import React from 'react'
import slideLayoutDefinitions from '../../slideLayoutDefinitions.js'

class StaticSlideChoiceZoomableVideoPlayer extends React.Component{
  
  render(){ 

    const {choiceIndex, mediaChoiceWidth} = this.props    
          
    const videoContainerWidth = mediaChoiceWidth - 6 // bring in from definitions with some 
    const videoContainerHeight = 284 // refactor template definitions      

    return(             
        <div id={`choice${choiceIndex}VideoZoomable`} style={{width: `${videoContainerWidth}px`, height: `${videoContainerHeight}px`}}  className='slide-choice-media--videoChoiceZoomable'>
        </div>
      )   
    } 
  }

 
export default StaticSlideChoiceZoomableVideoPlayer
