import React from 'react';
import EditorIcon from '../../../misc/EditorIcons'
import ChoiceVideoControlBar from './ChoiceVideoControlBar'
import slideLayoutDefinitions from '../../slideLayoutDefinitions.js'

class StaticSlideChoiceVideoPlayer extends React.Component{  

  constructor(props){
    super(props)
    this.onLoad=this.onLoad.bind(this)
  }


  onLoad(){
    if(this.props.choiceMediaPromiseResolve){
      console.log(`choice ${this.props.choiceLetter} video thumbnail onLoad`)
      this.props.choiceMediaPromiseResolve()
    }
  }






  render(){     
    
    const {slideWidth, video, questionChoiceCount} = this.props

    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale

    const mediaWidth = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].width
    const mediaHeight = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].height

    const mediaInternalSidePadding = 5

    const thumbnailContainerWidth = mediaWidth - mediaInternalSidePadding
    
    const thumbnailURL = video.thumbnail.src
    
    const thumbnailWidth = 1280
    const thumbnailHeight = 720

    // const thumbnailWidth = video.thumbnail.width
    // const thumbnailHeight = video.thumbnail.height

    const thumbnailContainerHeight = mediaHeight
      
    const thumbnailAspect = thumbnailWidth / thumbnailHeight
    const containerAspect = thumbnailContainerWidth / thumbnailContainerHeight

    let fitThumbnailWidth
    let fitThumbnailHeight
    let isPortrait
      
    if(thumbnailAspect >= containerAspect){
      //landscape     
      fitThumbnailWidth = thumbnailContainerWidth
      fitThumbnailHeight = thumbnailContainerWidth / thumbnailAspect
    }

    if(thumbnailAspect < containerAspect){
      //portrait
      fitThumbnailHeight = thumbnailContainerHeight
      fitThumbnailWidth = thumbnailContainerHeight * thumbnailAspect     
      isPortrait = true
    }
    

    // easier to just set custom top positions
    let topThumbnailOffset = 48
    let heightControlBar = mediaHeight - fitThumbnailHeight - topThumbnailOffset 
    let showDarkTopControls = false

    if(questionChoiceCount === 3){
      topThumbnailOffset = 0
      heightControlBar = mediaHeight - fitThumbnailHeight - topThumbnailOffset
      showDarkTopControls = true
      // showDarkTopControls = false
    }
    if(questionChoiceCount === 2){
      topThumbnailOffset = 0
      heightControlBar = 50
      showDarkTopControls = true
    }    

    const duration = video.end-video.start


    return (

        <div className='slide-choice-media slide-choice-media--video slide-choice-media--video--static'>                      
          
          <div className='slide-choice-media--video-topLeft'>            
            <div className={'slide-choice-media--video--static-videoIconContainer ' + (questionChoiceCount === 4 ? ' ' : ' slide-choice-media--video--static-videoIconContainer--overlay')}>
              <EditorIcon name='twoPartVideoIcon'/>
            </div>
          </div>          


          <div style={{width: `${fitThumbnailWidth}px`, height: `${fitThumbnailHeight}px`, top: `${topThumbnailOffset}px`}}  className='slide-choice-media--video--static-thumbnailContainer'>                                                                
            <img
              onLoad={this.onLoad}
              src={thumbnailURL}
              className='slide-choice-media--video--static-thumbnail'               
            />
          </div>


          <div style={{height:`${heightControlBar}px`, width: `${thumbnailContainerWidth}px`}} className='slide-media-choice--video-controlBarContainer'>
                            
            <ChoiceVideoControlBar 
              clipStartTime={video.start}
              clipEndTime={video.end}
              duration={duration}
              isClipped={video.isClipped}
              isPlaying={false}
              showInfo={true} // for nowPlaying, false
              overlayMode={questionChoiceCount === 2}
              videoTitle={video.title}
            />
              

          </div>                                  


          
          
          


          
          

        </div>
        

         
    )
  }
}


export default StaticSlideChoiceVideoPlayer



