module.exports ={
  // These are the available font sizes for the font resizing calculation
  // We render ghost elements for all of them, so increasing array size will decrease performance
  availableBodyFontSizes:[12,18,24,28,32,36,40,42,44,46,48,52,56,64],
  availableChoiceFontSizes:[12,18,20,24,26,28,30,32,36,38,42],

  // The slide has width 1280px and height 800px
  // There is a 60px padding around the edge
  // There is a 25px vertical margin between the body and choices
  // So 800px - 60px - 60px - 25px = 655px, the total available content height in a slide
  totalAvailableContentHeight:655, // 

  // For Body Center Media Center template, there is an additional vertical margin between the body and the media
  bodyCenterMediaCenterMarginBetweenBodyAndMedia: 14,

  // For Media Choices, there is an additional vertical margin between body and media, and we need "full container height" for media choices
  //bodyCenterMediaChoicesMarginBetweenBodyAndMedia: 14,  
  mediaChoiceFullContainerHeight: 342, 

  bodySoundPlayerHeight:270,

  mediaChoiceHeight:286,
  bodyMediaContainerDimensions:{  
    bodyLeftMediaRight: {
      "width":580,
      "height":680
    },
    bodyRightMediaLeft: {
      "width":580,
      "height":680
    },
    bodyCenterMediaCenter: {
      "width":1160
      //BodyCenterMediaCenter has a dynamic height passed through from font-size calculator
    },
    bodyLeftMediaRightChoicesMedia: {
      "width":580,
      "height":311
    },
  },

  choiceMediaChoiceDimensions:{  
    '2Choices':{
      "width":505,
      "height":286
    },
    '3Choices':{
      "width":370,
      "height":286
    },
    '4Choices':{
      "width":285,
      "height":286
    },
  },





  // "SpecialValues": {
  //   "BodyFontSizeArray": [
  //     12,      
  //     24,
  //     28,
  //     32,
  //     36,
  //     48,
  //     56,
  //     64
  //   ],
  //   "BodyFontCalculationBuffer": 10,
  //   "BodyMaxCharacterCount": 250,
  //   "ChoicesFontSizeArray": [
  //     12,
  //     16,      
  //     22,
  //     25,
  //     29,
  //     38
  //   ],
  //   "ChoicesCeilingHeight": {
  //     "BodyLeft": "400px",
  //     "BodyLeftImageRight": "400px",
  //     "BodyRightImageLeft": "400px",
  //     "BodyCenter": "350px",
  //     "BodyCenterImageCenter": "250px"
  //   },
  //   "ChoiceMaxCharacterCount": 200,
  //   "CalculatedBodyContainerHeight": {
  //     "BodyLeft": false,
  //     "BodyLeftImageRight": false,
  //     "BodyRightImageLeft": false,
  //     "BodyCenter": false,
  //     "BodyCenterImageCenter": true
  //   },
  //   "CalculatedBodyContainerMaxHeightArray": [
  //     220,
  //     220,
  //     220,
  //     195,
  //     176,
  //     114,
  //     0,
  //     105
  //   ]
  // }
}