import React from 'react'
import { Keyframes, animated, config } from 'react-spring/renderprops'
import delay from 'delay'

class NowPlayingPopBar extends React.PureComponent {
  render() {
    const {
      revealAnswer,
      isSurvey,
      isCorrect,
      correctChoiceLetter,
      correctChoiceBody,
    } = this.props

    let popBarType

    if (isSurvey) {
      popBarType = 'survey'
    }

    if (!isSurvey && revealAnswer && isCorrect) {
      popBarType = 'correct'
    }

    if (!isSurvey && revealAnswer && !isCorrect) {
      popBarType = 'incorrect'
    }

    const isGraded = !isSurvey

    let answerChoiceText
    let answerChoiceLetter

    if (correctChoiceLetter) {
      answerChoiceLetter = correctChoiceLetter
    }

    if (correctChoiceBody) {
      answerChoiceText = correctChoiceBody
    }

    let popBarPrimaryText
    let popBarSecondaryText

    if (popBarType === 'correct') {
      popBarPrimaryText = 'You were correct!'
    }
    if (popBarType === 'incorrect') {
      popBarPrimaryText = 'You were incorrect'
    }
    if (correctChoiceBody && (popBarType === 'correct' || popBarType === 'incorrect')) {
      popBarSecondaryText = `The answer was ${answerChoiceText} (${answerChoiceLetter})`
    }
    if (this.props.correctChoiceIsMedia && (popBarType === 'correct' || popBarType === 'incorrect')) {
      popBarSecondaryText = `The answer was ${answerChoiceLetter}`
    }
    if (popBarType === 'survey') {
      popBarPrimaryText = 'Survey question'
      popBarSecondaryText = 'There are no right and wrong answers!'
    }

    const popBarDefaultHeight = 90
    const popBarProgressBarHeight = 40
    const popBarProgressBarTop = (popBarDefaultHeight - popBarProgressBarHeight) / 2

    const GradedPopBarAnimation = Keyframes.Spring(async (next) => {
      if (isGraded && revealAnswer) {
        await next({
          from: { opacity: 0, top: '50px', height: `${popBarProgressBarHeight}px` },
          opacity: 1,
          height: `${popBarProgressBarHeight}px`,
          top: `${popBarProgressBarTop}px`,
          // config: config.wobbly,
        })
        await delay(200)
        await next({
          height: `${popBarDefaultHeight}px`,
          top: '0px',
          config: { tension: 185, friction: 22 },
        })
      }
    })

    const GradedPopBarAnimationProgressBar = Keyframes.Spring(async (next) => {
      if (isGraded && revealAnswer) {
        await delay(200)
        await next({
          from: { opacity: 0 },
          opacity: 1,
          // config: config.wobbly,
        })
        await next({
          opacity: 0,
          // config: config.wobbly,
        })
      }
    })

    const GradedPopBarAnimationProgressBarBar = Keyframes.Spring(async (next) => {
      if (isGraded && revealAnswer) {
        await delay(100)
        await next({
          from: { width: '0%' },
          width: '100%',
          config: { duration: 375 },
        })
      }
    })

    const GradedPopBarAnimationBG = Keyframes.Spring(async (next) => {
      if (isGraded && revealAnswer) {
        await delay(1700)
        await next({
          from: { opacity: '0%' },
          opacity: '5%',
          config: config.wobbly,
        })
      }
    })

    const GradedPopBarAnimationText = Keyframes.Spring(async (next) => {
      if (isGraded && revealAnswer) {
        await delay(1500)
        await next({
          from: { opacity: '0%' },
          opacity: '100%',
        })
      }
    })

    const SurveyPopBarAnimation = Keyframes.Spring(async (next) => {
      if (isSurvey && !this.props.isScanning) {
        await delay(900)
        await next({
          from: { opacity: 0, transform: 'translateY(5px)' },
          opacity: 1,
          transform: 'translateY(0px)',
          config: config.wobbly,
        })
        await delay(3000)
        await next({
          opacity: 0,
          transform: 'translateY(25px)',
          config: config.wobbly,
        })
      }
    })

    return (
      <React.Fragment>

        <GradedPopBarAnimation native>
          {(props) => (
            <animated.div className={`nowPlaying-popBar ${popBarType ? `nowPlaying-popBar--${popBarType}` : ''}`} style={{ ...props }}>

              <GradedPopBarAnimationProgressBar>
                {(props) => (
                  <animated.div className="nowPlaying-popBar-progressBar" style={{ ...props }}>
                    <GradedPopBarAnimationProgressBarBar>
                      {(props) => (
                        <animated.div className="nowPlaying-popBar-progressBar-bar" style={{ ...props }} />
                      )}
                    </GradedPopBarAnimationProgressBarBar>

                  </animated.div>
                )}
              </GradedPopBarAnimationProgressBar>

              <GradedPopBarAnimationText>
                {(props) => (
                  <animated.div className="nowPlaying-popBar-text" style={{ ...props }}>
                    <div className="nowPlaying-popBar-text-primary">
                      {popBarPrimaryText}
                    </div>
                    <div className="nowPlaying-popBar-text-secondary">
                      {popBarSecondaryText}
                    </div>
                  </animated.div>
                )}
              </GradedPopBarAnimationText>

              <GradedPopBarAnimationBG>
                {(props) => (
                  <animated.div className="nowPlaying-popBar-bg" style={{ ...props }} />
                )}
              </GradedPopBarAnimationBG>

            </animated.div>
          )}
        </GradedPopBarAnimation>

        <SurveyPopBarAnimation native>
          {(props) => (
            <animated.div className="nowPlaying-popBar nowPlaying-popBar--survey" style={{ ...props }}>
              <div className="nowPlaying-popBar-text">
                <div className="nowPlaying-popBar-text-primary">
                  {popBarPrimaryText}
                </div>
                <div className="nowPlaying-popBar-text-secondary">
                  {popBarSecondaryText}
                </div>
              </div>
              <div className="nowPlaying-popBar-bg" />
            </animated.div>
          )}
        </SurveyPopBarAnimation>

      </React.Fragment>
    )
  }
}

export default NowPlayingPopBar
