import katex from 'katex'

function htmlDecode(input) {
	let escapedInput=input.replace(/</g, '&lt')
	escapedInput=escapedInput.replace(/>/g, '&gt')
	var doc = new DOMParser().parseFromString(escapedInput, "text/html")
	return doc.documentElement.textContent
}


function getKatexHTML(latex){
	const parsedLatex=htmlDecode(latex)
	const html = katex.renderToString(parsedLatex, {
	    throwOnError: false,	   
      output: "html",
	})
	return html
}

export default function createMarkup(input) {
	let newHTML=''
	if(input){
		newHTML = input.replace(/<math data-latex="((.|\n)*?)"><\/math>/g,  function (outer, inner){
		const katexHTML=getKatexHTML(inner)
		return `<span class="katexOuterContainer">
				<span class="katexInnerContainer">
					<span class="katexInnerContainer-content">
						${katexHTML}
					</span>
				</span>
			</span>`
	}) 
	}

  return {__html: newHTML}
}
