import React from 'react'
import EditorIcon from '../misc/EditorIcons'

class StaticLetterBlock extends React.Component{

	render(){	
		const {index, isReviewScreen, showCorrect, isSurvey, isCorrect, reportView, alwaysShowLetterblock} = this.props
		
		// let showLetterBlock = true

	 //    if(!alwaysShowLetterblock && isReviewScreen && !reportView && !isSurvey && showCorrect){
	 //       showLetterBlock = false
	 //    }

    	//const showIcon=!showLetterBlock

		return(
			<div style={this.props.style} className='slide-choice-letterblock slide-choice-letterblock--static'>   		
    		 	{index === 0 &&
	     			<EditorIcon name='choiceLetterBlock-A' />
	     		}
	     		{index === 1 &&
	     			<EditorIcon name='choiceLetterBlock-B' />
	     		}
	     		{index === 2 &&
	     			<EditorIcon name='choiceLetterBlock-C' />
	     		}
	     		{index === 3 &&
	     			<EditorIcon name='choiceLetterBlock-D' />
	     		}	     		

    		 <div className='slide-choice-letterblock-deviceBlobContainer'>
            <div className='slide-choice-letterblock-deviceBlob'>
                <div className='slide-choice-letterblock-deviceBlob-inner'/>
            </div>
        </div>    	

			</div>
		)		
	}	
}


export default StaticLetterBlock
