function getInitialState() {
  const session = JSON.parse(localStorage.getItem('session'))
  if (session) {
    return session.token
  } return null
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case 'FETCH_TOKEN_SUCESS':
      return action.token
    case 'CLEAR_ALL_DATA':
      return null
    default:
      return state
  }
}
