import React from 'react'
import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import {formatVideoDuration} from './formatVideoDuration'



class ChoiceVideoControlBar extends React.Component{  
	

	render(){
		const {duration,isPlaying,playerCurrentTime,clipStartTime}=this.props

		const startTime=clipStartTime||0

		let currentTimestamp
		if(this.props.clipStartTime){	
			currentTimestamp = formatVideoDuration(Math.max(playerCurrentTime-clipStartTime,0))
		}else{
			currentTimestamp = formatVideoDuration(playerCurrentTime)

		}
		
		const fullTimestamp=formatVideoDuration(duration)

		const lengthSeconds = duration

	
	  return (
	  	<div className='slide-media-choice--video-controlBar'>	  		
  			<div className={'slide-media-choice--video-controlBar-seekBar ' + (this.props.overlayMode ? ' slide-media-choice--video-controlBar-seekBar--overlayMode': '')}>
  				<Slider 
  					onBeforeChange={this.props.onBeforeChange}
  					onAfterChange={this.props.onAfterChange}
  					onChange={(value)=>{this.props.seekTo(value+clipStartTime)}}
  					value={playerCurrentTime-startTime} 
  					min={0} 
  					max={lengthSeconds} 
  					defaultValue={0} 
  					step={0.01}
  				/>  				
  			</div>
	  			  			  		

					<div className={'slide-media-choice--video-controlBar-timestamp ' + (this.props.overlayMode ? 'slide-media-choice--video-controlBar-timestamp--overlayMode': '')}>
	  				{isPlaying && 	  				
	  					<div className='slide-media-choice--video-controlBar-playingTimestamps'>			  				
			  				{currentTimestamp}			  				
		  				</div>
		  			}
		  			{!isPlaying &&
	  					<div onClick={this.props.showEditVideoModal} className='slide-media-choice--video-controlBar-initialTitleAndTimestamp'>
	  						{/*}
	  						<div className='slide-media-choice--video-controlBar-initialTitleAndTimestamp-title'>
	  							{this.props.videoTitle}
	  						</div>
	  						*/}
	  						<div className='slide-media-choice--video-controlBar-initialTitleAndTimestamp-timestamp'>
	  							{fullTimestamp}
	  						</div>		  					
		  				</div>
		  			}
	  			</div>
	  			

	  	</div>
	  )
	}
}

export default ChoiceVideoControlBar
