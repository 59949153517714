import React from 'react'
import slideLayoutDefinitions from '../../slideLayoutDefinitions.js'

class StaticSlideChoiceZoomableSoundPlayer extends React.Component{
  
  render(){ 

    const {choiceIndex, mediaChoiceWidth} = this.props    
          
    const soundContainerWidth = mediaChoiceWidth - 6 // bring in from definitions with some 
    const soundContainerHeight = 284 // refactor template definitions      

    return(             
        <div id={`choice${choiceIndex}SoundPlayerZoomable`} style={{width: `${soundContainerWidth}px`, height: `${soundContainerHeight}px`}}  className='slide-choice-media--sound-soundPlayerZoomable'>          
        </div>
      )   
    } 
  }


export default StaticSlideChoiceZoomableSoundPlayer
