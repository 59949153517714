import React from 'react'
import useSound from 'use-sound'
import popDown from '../../sounds/pop-down.mp3'
import popUpOn from '../../sounds/pop-up-on.mp3'

const lettersArray = ['A', 'B', 'C', 'D']

const ChoiceButton = (props) => {
  // const [play] = useSound(popDown);
  const [playActive] = useSound(popDown, { volume: 0.1 })
  const [playOn] = useSound(popUpOn, { volume: 0.1 })
  const {
    choice,
    choiceFontSize,
    choiceIndex,
    isSelected,
    onSelectChoice,
    colorScheme,
    isSurveyQuestion,
    isReviewScreen,
    isAskScreen,
    showCorrect,
    isScanScreen,
    showResponseCounts,
    choiceMediaType,
    choiceStyle,
    isChoiceMedia,
  } = props

  const isInteractiveMediaChoice = choiceMediaType === 'video' || choiceMediaType === 'sound'

  return (
    <button
      onMouseDown={function () {
        if (isScanScreen) {
          playActive()
        }
      }}
      onMouseUp={function () {
        if (isScanScreen) {
          playOn()
        }
      }}
      onClick={function () {
        if (isScanScreen) {
          onSelectChoice(lettersArray[choiceIndex])
        }
      }}
      style={{ fontSize: `${choiceFontSize}px`, ...choiceStyle }}
      className={`slide-choice slide-choice--index--${choiceIndex}${choice.correct ? ' slide-choice--correct' : ' slide-choice--incorrect'}${colorScheme ? ` slide-choice--${colorScheme} ` : ''}${isSurveyQuestion ? ' slide-choice--survey' : ' slide-choice--graded'}${isReviewScreen ? ' choice--review ' : ' choice--notReview '}${isAskScreen ? ' slide-choice--ask' : ' slide-choice--notAsk '}${showCorrect ? ' slide-choice--showCorrect' : ' slide-choice--notShowCorrect'}${isScanScreen ? ' slide-choice--scan' : ' slide-choice--notScan'}${showResponseCounts ? ' slide-choice--showResponseCounts ' : ' slide-choice--notShowResponseCounts '}${isSelected ? ' slide-choice--deviceSelected ' : ' slide-choice--notDeviceSelected '}${isChoiceMedia ? ' slide-choice--mediaChoice ' : ' slide-choice--textChoice '}${isInteractiveMediaChoice ? ' slide-choice--interactiveMediaChoice ' : ' slide-choice--notInteractiveMediaChoice '}`}
      key={choiceIndex}
    >
      {props.children}
    </button>
  )
}

export default ChoiceButton
