import React, { Component } from 'react'
import Clock from 'react-live-clock'

class HomePageEmptyState extends Component {
  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('Student Now Playing Empty State')
    }
  }

  render() {
    const { activeSection } = this.props
    return (
      <div className="nowPlaying nowPlaying--emptyState">
        <div className="nowPlaying-home" onClick={this.props.closeNowPlaying}>
          <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.8354471,6.73149271 C32.3906857,5.17625413 32.3824408,2.69209514 30.8451728,1.15482716 C29.2971873,-0.393158373 26.8111466,-0.378086464 25.2685073,1.16455289 L16,10.4330602 L6.73149271,1.16455289 C5.17625413,-0.390685684 2.69209514,-0.382440823 1.15482716,1.15482716 C-0.393158373,2.70281269 -0.378086464,5.18885335 1.16455289,6.73149271 L10.4330602,16 L1.16455289,25.2685073 C-0.390685684,26.8237459 -0.382440823,29.3079049 1.15482716,30.8451728 C2.70281269,32.3931584 5.18885335,32.3780865 6.73149271,30.8354471 L16,21.5669398 L25.2685073,30.8354471 C26.8237459,32.3906857 29.3079049,32.3824408 30.8451728,30.8451728 C32.3931584,29.2971873 32.3780865,26.8111466 30.8354471,25.2685073 L21.5669398,16 L30.8354471,6.73149271 Z" />
          </svg>
        </div>
        {activeSection && (
        <div className="nowPlaying--emptyState-block">
          <div className="nowPlaying--emptyState-block-title">
            waiting for
            {' '}
            <span className={`nowPlaying--emptyState-block-title--sectionTitle ${activeSection.color ? `nowPlaying--emptyState-block-title--sectionTitle--${activeSection.color}` : ''}`}>{activeSection.name}</span>
            {' '}
            to start
          </div>
          <div className="nowPlaying--emptyState-block-time">
            <Clock format="hh:mm:ss A" ticking={false} />
          </div>
        </div>
        )}
      </div>
    )
  }
}

export default HomePageEmptyState
