import Pusher from 'pusher-js'
import store from '../store'
import { sectionReceived } from '../actions/sections'

// http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#section-channel
export function sectionPusherSetup(sectionId, studentId) {
  const state = store.getState()
  let pusher
  if (state.pusher) {
    pusher = state.pusher
  } else {
    const session = JSON.parse(localStorage.getItem('session'))
    const token = session ? session.token : state.token
    if (!token) { return }
    // Pusher.logToConsole = true
    pusher = new Pusher(process.env.REACT_APP_PLICKERS_PUSHER_TOKEN, {
      cluster: 'mt1',
      channelAuthorization: {
        endpoint: `${process.env.REACT_APP_PLICKERS_API_ENDPOINT}/pusher/auth-section`,
        headers: { 'x-auth-token': token },
      },
    })
    store.dispatch({ type: 'CREATE_PUSHER_SUCCESS', pusher })
  }
  subscribeToChannel(pusher, sectionId, studentId)
}

function subscribeToChannel(pusher, sectionId, studentId) {
  let retryCount = 0

  const presenceChannel = pusher.subscribe(`presence-${sectionId}`)
  presenceChannel.bind('now-playing updated', handleNPPusherReceived)
  presenceChannel.bind('pusher:subscription_error', (errorObject) => {
    const { status, type, error } = errorObject
    if (window.analytics) {
      window.analytics.track('pusher:subscription_error', {
        status,
        type,
        error,
        sectionId,
        studentId,
      })
    }
    if (status === 503 && retryCount < 2) {
      retryCount += 1
      pusher.subscribe(`presence-${sectionId}`)
    }
  })
}

export function sectionPusherUnsubscribe(sectionId) {
  const state = store.getState()
  const { pusher } = state
  if (pusher) {
    pusher.unsubscribe(`presence-${sectionId}`)
  }
}

function handleNPPusherReceived(data) {
  store.dispatch(sectionReceived(data))
}
