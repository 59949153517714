import api from '../api'

export function fetchSection(sectionId) {
  return (dispatch) => api.fetch(`/student/sections/${sectionId}`)
    .then((response) => {
      const section = response
      dispatch({ type: 'FETCH_SECTION_SUCCESS', section })
      return response
    })
    .catch(() => {})
}

export const sectionReceived = (section) => ({ type: 'SECTION_RECEIVED', section })
