import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import session from './session'
import sections from './sections'
import pusher from './pusher'
import token from './token'
import clockSyncOffset from './clockSyncOffset'

const rootReducer = combineReducers({
	session,
	clockSyncOffset,
	sections,
	pusher,
	token,
	routing:routerReducer})

export default rootReducer