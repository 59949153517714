import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Spring, Transition, animated } from 'react-spring/renderprops'
import Measure from 'react-measure'
import findIndex from 'lodash/findIndex'
import RichStaticSlide from './richStaticSlide/RichStaticSlide'
import NowPlayingPageTransitionComponent from './NowPlayingPageTransitionComponent'
import NowPlayingPopBar from './NowPlayingPopBar'
import NowPlayingEmptyState from './NowPlayingEmptyState'
import NowPlayingCountdown from './NowPlayingCountdown'

const LETTER_ARRAY = ['A', 'B', 'C', 'D']

class NowPlayingPage extends Component {
  constructor() {
    super()
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.state = ({
      dimensions: {
        width: -1,
        height: 2000,
        countdownFinished: false,
      },
    })
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.nowPlaying !== this.props.nowPlaying) {
      this.setState({ countdownFinished: false })
    }
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) { // escape key
      this.props.closeNowPlaying()
    }
  }

  render() {
    const {
      nowPlaying,
      selectedChoice,
      activeSection,
      studentFirstName,
    } = this.props
    let snapshot
    let isScanning
    let results
    let totalResponseCount = 0
    if (nowPlaying) {
      snapshot = nowPlaying.snapshot
      isScanning = nowPlaying.scanning
      results = nowPlaying.results
      if (results) {
        Object.keys(results).forEach((key) => {
          totalResponseCount += results[key]
        })
      }
    }

    if (this.state.countdownFinished) {
      isScanning = false
    }

    const showCorrect = nowPlaying && nowPlaying.revealAnswer
    const showGraph = nowPlaying && nowPlaying.showGraph

    let studentResponseCorrect = false
    if (showCorrect && selectedChoice && snapshot) {
      const indexOfStudentResponse = findIndex(LETTER_ARRAY, (letter) => letter === selectedChoice)
      if (snapshot.choices[indexOfStudentResponse].correct) {
        studentResponseCorrect = true
      }
    }

    let correctChoiceBody
    let correctChoiceLetter
    let correctChoiceIsMedia = false
    if (showCorrect && snapshot) {
      snapshot.choices.forEach((choice, index) => {
        if (choice.correct) {
          correctChoiceBody = choice.body
          if (choice.media) {
            correctChoiceIsMedia = true
          }
          correctChoiceLetter = LETTER_ARRAY[index]
        }
      })
    }
    const { width, height } = this.state.dimensions
    const internalScreenMargin = 20
    let slideWidth
    if (width && height) {
      if (width / 1.6 > height) {
        slideWidth = height * 1.6 - internalScreenMargin - internalScreenMargin
      } else {
        slideWidth = width - internalScreenMargin - internalScreenMargin
      }
    }

    const slideHeight = slideWidth / 1.6
    const nonScanSlideScale = 0.8
    const topPanelHeight = height - (slideHeight * 0.8 * 0.93)
    const transformScalePopBar = (1 - ((1 - (slideWidth / 1280)) / 2))
    const negativeMarginPopContainer = 0.03 * slideHeight * nonScanSlideScale
    const popBarContainerTop = (slideHeight * nonScanSlideScale) +
      ((height - (slideHeight * nonScanSlideScale)) / 2) -
      negativeMarginPopContainer
    const countdownEndsAt = nowPlaying ? nowPlaying.countdownEndsAt : null

    if (snapshot) {
      return (
        <Measure
          bounds
          onResize={(contentRect) => {
            this.setState({ dimensions: contentRect.bounds })
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className={`nowPlaying ${isScanning ? ' nowPlaying--isScanning' : ' nowPlaying--notScanning'}`}>
              {
                (
                  snapshot.survey ||
                  (
                    selectedChoice &&
                    showCorrect &&
                    (correctChoiceBody || correctChoiceIsMedia) &&
                    correctChoiceLetter
                  )
                ) && (
                  <div style={{ top: `${popBarContainerTop}px`, transform: `scale(${transformScalePopBar})` }} className="nowPlaying-popBarContainer">
                    <NowPlayingPopBar
                      revealAnswer={showCorrect}
                      isSurvey={snapshot.survey}
                      isCorrect={studentResponseCorrect}
                      correctChoiceLetter={correctChoiceLetter}
                      correctChoiceBody={correctChoiceBody}
                      correctChoiceIsMedia={correctChoiceIsMedia}
                      isScanning={isScanning}
                    />
                  </div>
                )
              }

              <div className={`nowPlaying-countdownTimerContainer ${selectedChoice ? 'nowPlaying-countdownTimerContainer--selectedChoice' : ''}`}>
                <Transition
                  native
                  items={countdownEndsAt && isScanning}
                  from={{ transform: 'translate3d(0,-20px,0)', opacity: 0.5 }}
                  enter={{ transform: 'translate3d(0,0px,0)', opacity: 1 }}
                  leave={{ transform: 'translate3d(0,-20px,0)', opacity: 0 }}
                  config={{ tension: 165, friction: 14 }}
                >
                  {(show) => show && ((props) => (
                    <animated.div style={props} className={`nowPlaying-countdownTimer ${activeSection.color ? `nowPlaying-countdownTimer--classColor--${activeSection.color}` : ''}`}>
                      <NowPlayingCountdown
                        countdownEndsAt={countdownEndsAt}
                        onCountdownFinish={() => { this.setState({ countdownFinished: true }) }}
                      />
                    </animated.div>
                  )
                  )}
                </Transition>
              </div>

              <div className="nowPlaying-home" onClick={this.props.closeNowPlaying}>
                <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30.8354471,6.73149271 C32.3906857,5.17625413 32.3824408,2.69209514 30.8451728,1.15482716 C29.2971873,-0.393158373 26.8111466,-0.378086464 25.2685073,1.16455289 L16,10.4330602 L6.73149271,1.16455289 C5.17625413,-0.390685684 2.69209514,-0.382440823 1.15482716,1.15482716 C-0.393158373,2.70281269 -0.378086464,5.18885335 1.16455289,6.73149271 L10.4330602,16 L1.16455289,25.2685073 C-0.390685684,26.8237459 -0.382440823,29.3079049 1.15482716,30.8451728 C2.70281269,32.3931584 5.18885335,32.3780865 6.73149271,30.8354471 L16,21.5669398 L25.2685073,30.8354471 C26.8237459,32.3906857 29.3079049,32.3824408 30.8451728,30.8451728 C32.3931584,29.2971873 32.3780865,26.8111466 30.8354471,25.2685073 L21.5669398,16 L30.8354471,6.73149271 Z" />
                </svg>
              </div>

              <NowPlayingPageTransitionComponent nowPlaying={this.props.nowPlaying}>
                <Spring
                  config={{ tension: 170, friction: 26 }}
                  to={{
                    slideScale: isScanning ? (selectedChoice ? 0.9 : 1) : nonScanSlideScale,
                  }}
                >
                  { (props) => (
                    <div style={{ width: `${slideWidth}px`, height: `${slideWidth / 1.6}px`, transform: `scale(${props.slideScale})` }} className={`nowPlaying-slideContainerInner ${isScanning ? '' : 'nowPlaying-slideContainerInner--notScanning'}`}>
                      <RichStaticSlide
                        question={snapshot}
                        isAskScreen={!selectedChoice && isScanning}
                        isScanScreen={isScanning}
                        isReviewScreen={!isScanning}
                        mode="view"
                        slideWidth={slideWidth}
                        previewCard
                        selectedChoice={selectedChoice}
                        onSelectChoice={this.props.selectChoice}
                        showCorrect={showCorrect}
                        showGraph={showGraph}
                        results={results}
                        totalResponseCount={totalResponseCount}
                        mediaIsPlayable
                      />
                    </div>
                  )}
                </Spring>
              </NowPlayingPageTransitionComponent>

              {activeSection && (
                <React.Fragment>
                  <div className={`nowPlaying-topPanel-sectionBlobContainer ${isScanning ? ' nowPlaying-topPanel-sectionBlobContainer--scanning ' : ' nowPlaying-topPanel-sectionBlobContainer--notScanning'}`}>
                    <div className={`nowPlaying-topPanel-sectionBlob ${activeSection.color ? `nowPlaying-topPanel-sectionBlob--classColor--${activeSection.color}` : ''}`}>
                      <div className="nowPlaying-topPanel-sectionBlob-label">
                        {activeSection.name}
                      </div>
                    </div>
                  </div>
                  <div className={`nowPlaying-topPanel-studentNameBlobContainer ${isScanning ? ' nowPlaying-topPanel-studentNameBlobContainer--scanning ' : ' nowPlaying-topPanel-studentNameBlobContainer--notScanning'}`}>
                    <div className="nowPlaying-topPanel-studentNameBlob">
                      <div className="nowPlaying-topPanel-studentNameBlob-label">
                        {studentFirstName}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              <Spring
                to={{
                  translateYTop: isScanning ? (topPanelHeight * -1.2) : 0,
                }}
              >
                { (props) => (
                  <div style={{ height: `${topPanelHeight}px`, transform: `translateY(${props.translateYTop}px)` }} className="nowPlaying-topPanel" />
                )}
              </Spring>

              <Spring
                config={{ tension: 170, friction: 26 }}
                to={{
                  opacity: isScanning ? 1 : 0,
                }}
              >
                { (props) => <div style={{ opacity: `${props.opacity}` }} className="nowPlaying-scanBG" />}
              </Spring>
            </div>
          )}
        </Measure>
      )
    }
    return (
      <NowPlayingEmptyState
        activeSection={activeSection}
        closeNowPlaying={this.props.closeNowPlaying}
      />
    )
  }
}

export default withRouter(NowPlayingPage)
