import api from '../api'
import { fetchSection } from './sections'
import { sectionPusherSetup, sectionPusherUnsubscribe } from '../utils/sectionPusher'

export function createSession() {
  return (dispatch) => api.post('/student/sessions')
    .then((response) => {
      localStorage.setItem('session', JSON.stringify(response))
      dispatch({ type: 'CREATE_SESSION_SUCESS', response })
      const { token } = response
      dispatch({ type: 'FETCH_TOKEN_SUCESS', token })
      return response
    })
}

export function addAuthorizationToSession(sessionId, studentCode) {
  const data = { studentCode }
  return (dispatch) => api.put(`/student/sessions/${sessionId}`, data)
    .then((response) => {
      localStorage.setItem('session', JSON.stringify(response))
      dispatch({ type: 'UPDATE_SESSION_SUCESS', response })
      response.authorizations.forEach((authorization) => {
        dispatch(fetchSection(authorization.section.id))
        sectionPusherSetup(authorization.section.id, authorization.student.id)
      })
      if (window.analytics) {
        window.analytics.track('Add authorization', {
          studentCode,
          sessionId,
        })
      }
      return response
    })
    .catch((err) => {
      if (window.analytics) {
        window.analytics.track('Failed to add authorization', { studentCode })
      }
      throw (err)
    })
}

export function fetchSession(sessionId) {
  return (dispatch) => api.fetch(`/student/sessions/${sessionId}`)
    .then((response) => {
      localStorage.setItem('session', JSON.stringify(response))
      dispatch({ type: 'CREATE_SESSION_SUCESS', response })
      const { token } = response
      dispatch({ type: 'FETCH_TOKEN_SUCESS', token })
      return response
    })
    .catch(() => {})
}

export function deleteAuthorizationFromSession(sessionId, studentId, sectionId) {
  return (dispatch) => api.delete(`/student/sessions/${sessionId}/authorizations/${studentId}`)
    .then((response) => {
      dispatch(fetchSession(sessionId))
      sectionPusherUnsubscribe(sectionId)
      if (window.analytics) {
        window.analytics.track('Remove authorization', {
          sessionId,
        })
      }
      return response
    })
    .catch(() => {})
}

export function clearAllSessionData() {
  return (dispatch) => {
    dispatch({ type: 'CLEAR_ALL_DATA' })
    dispatch(createSession())
  }
}
