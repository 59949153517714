import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'
import {composeSoundWaveformUrl} from '../../composeSoundWaveformUrl'
import slideLayoutDefinitions from '../../slideLayoutDefinitions.js'
import {formatMillisecondDuration} from './formatMillisecondDuration'
 
class StaticSlideSoundStatic extends React.Component{  

  constructor(props) {
    super(props) 
    this.onLoad=this.onLoad.bind(this)
    let waveformImageURL
    if(props.showWaveform){
      waveformImageURL=composeSoundWaveformUrl(props.sound.fileId,props.sound.start,props.sound.end,props.sound.isClipped)
    }
    let duration=props.sound.end-props.sound.start
    this.state = {    
      waveformImageURL: waveformImageURL,   
      duration:duration,
    }  
  }

  componentDidMount(){
    if(!this.props.showWaveform){
      this.props.questionMediaPromiseResolve()
    }
  }
  onLoad(){
    if(this.props.questionMediaPromiseResolve){
      console.log('question sound waveform onLoad')
      this.props.questionMediaPromiseResolve()
    }
  }


 
  

  render(){ 

   
    const {slideWidth, slideTemplate,sound,showWaveform} = this.props
    const {duration,waveformImageURL} = this.state
        
    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale

    const ceilingInverseScale = 1.55
    const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)

    let mediaWidth

    let widePlayer = false

    const soundContainerHeight = slideLayoutDefinitions.bodySoundPlayerHeight
    
    if((slideTemplate === 'bodyLeftMediaRight') || (slideTemplate === 'bodyRightMediaLeft') || (slideTemplate === 'bodyLeftMediaRightChoicesMedia')){
      mediaWidth = 580 // should come from template definitions or somewhere      
    }
    if(slideTemplate === 'bodyCenterMediaCenter'){
      mediaWidth = 1160 // should come from template definitions or somewhere      
      widePlayer = true      
    }    

    const soundPlayerWidth = mediaWidth

    const scaledSoundPlayeContainerHeight = soundContainerHeight / UIInverseScale
    const scaledSoundPlayerWidth = soundPlayerWidth / UIInverseScale


    const lengthSeconds = duration // passed through


    const soundClipTitle = this.props.sound.title

    let displayMillisecondGranularity = true
      let displayHourGranularity=false
      if((this.state.duration) >1){ // if over 1 second 
        displayMillisecondGranularity = false // don't show millisecond granularity
        if(this.state.duration>60*60){
          displayHourGranularity=true
        }
      }



    return (

        <React.Fragment>                      
            <div style={{width: `${soundPlayerWidth}px`, height: soundContainerHeight ? `${soundContainerHeight}px` : null}} className={'slide-media--sound slide-media--sound--fullyStatic ' + (widePlayer ? ' slide-media--sound--widePlayer' : '')}>          
                                      
              <div className='slide-media-sound--infoAndTimestampContainer'>                
                <div className='slide-media-sound--infoAndTimestamp'>
                  <div className='slide-media-sound--infoAndTimestamp-title'>
                    <EditorIcon name='insertSound' />
                    {soundClipTitle}
                  </div>
                  <div className='slide-media-sound--infoAndTimestamp-timestamp'>
                    {formatMillisecondDuration(duration*1000,displayMillisecondGranularity,displayHourGranularity)}
                  </div>
                </div>         
                            
              </div>

              <div className='slide-media--sound-seekBarContainer'>                                          
                  
                  <div className='slide-media--sound-seekBarContainer-waveformImageContainer'>                                  
                    {showWaveform &&
                      <img 
                        onLoad={this.onLoad}
                        src={waveformImageURL} />                                  
                    }
                  </div>
                
              </div>                    
          </div>          
                
         </React.Fragment>
    )
  }
}


export default StaticSlideSoundStatic

