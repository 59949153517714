import moment from 'moment'
import api from '../api'
import store from '../store'

const MAX_ROUND_TRIP_TIME = 2000// 2 seconds
const RETRY_INTERVAL = 10000 // 10 seconds

export default function syncClock() {
  const sentTime = moment()
  return (dispatch) => api.fetch('/current-time/')
    .then((response) => {
      const receivedTime = moment()
      const roundTripTime = receivedTime.diff(sentTime)
      const serverTime = moment(response.currentTime)
      const clockOffset = (serverTime.add(roundTripTime / 2)).diff(receivedTime)

      if (roundTripTime < MAX_ROUND_TRIP_TIME) {
        const state = store.getState()
        const { session } = state
        dispatch({ type: 'CLOCK_SYNC_SUCCESS', clockOffset })
        if (window.analytics) {
          window.analytics.track('Sync Clock', {
            offsetInMilliseconds: clockOffset,
            session,
          })
        }
      } else {
        setTimeout(() => { // Start the timer
          dispatch(syncClock())
        }, RETRY_INTERVAL)
      }
    })
}
