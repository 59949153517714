import store from './store'

const API = process.env.REACT_APP_PLICKERS_API_ENDPOINT
const API_VERSION = '4.0.0'

function headers() {
  const session = JSON.parse(localStorage.getItem('session'))
  let token = ''
  if (session) {
    token = session.token
  } else {
    const state = store.getState()
    token = state.token || ''
  }

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-auth-token': token,
    'x-api-version': API_VERSION,
  }
}

function patchHeaders() {
  const session = JSON.parse(localStorage.getItem('session'))
  let token = ''
  if (session) {
    token = session.token
  } else {
    const state = store.getState()
    token = state.token
    if (window.analytics) {
      window.analytics.track('No token in local storage', {
        hasReduxToken: !!token,
        patchRequest: true,
      })
    }
  }
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-auth-token': token,
    'X-HTTP-Method-Override': 'PATCH',
    'x-api-version': '4.4.0',
  }
}

function parseResponse(response) {
  if (response.statusText !== 'No Content') {
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json)
        }
        return json
      })
    }
    return response.text().then((text) => text)
  }
}

function checkStatus(response) {
  if (response.ok) {
    return response
  }
  if (response && response.statusText) {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  } else {
    const error = new Error()
    throw error
  }
}

function queryString(params) {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')
  return `${query.length ? '?' : ''}${query}`
}

function fetchRetry(url, options) {
  const delay = 100 // delay in ms before retry request
  const limit = 2 // max number request repeats
  return new Promise(((resolve, reject) => {
    const wrappedFetch = function (limit) {
      fetch(url, options)
        .then((response) => {
          if (response.status !== 503) { // only retry on 503 errors
            resolve(response)
          } else if (limit > 0) {
            retry(limit)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          if (limit > 0) {
            retry(limit)
          } else {
            reject(error)
          }
        })
    }

    function retry(n) {
      if (process.env.REACT_APP_ENV !== 'production') {
        console.log(`************Retry request: attempt ${limit - n + 1} *******************`)
      }
      setTimeout(() => {
        wrappedFetch(n - 1)
      }, delay)
    }

    wrappedFetch(limit)
  }))
}

export default {
  fetch(url, params = {}) {
    return fetchRetry(`${API}${url}${queryString(params)}`, {
      // return fetch(`${API}`, { //for testing response codes
      method: 'GET',
      headers: headers(),
    })
      .then(checkStatus)
      .then(parseResponse)
  },

  post(url, data) {
    const body = JSON.stringify(data)
    return fetchRetry(`${API}${url}`, {
      // return fetchRetry(`${API}`, {
      method: 'POST',
      headers: headers(),
      body,
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  put(url, data) {
    const body = JSON.stringify(data)
    return fetchRetry(`${API}${url}`, {
      // return fetch(`${API}`, {
      method: 'PUT',
      headers: headers(),
      body,
    })
      .then(checkStatus)
      .then(parseResponse)
  },

  patch(url, data) {
    const body = JSON.stringify(data)
    return fetch(`${API}${url}`, {
      method: 'POST',
      headers: patchHeaders(),
      body,
    })
      .then(checkStatus)
      .then(parseResponse)
  },

  delete(url, data) {
    const body = JSON.stringify(data)
    return fetchRetry(`${API}${url}`, {
      method: 'DELETE',
      headers: headers(),
      body,
    })
      .then(checkStatus)
  },

}
