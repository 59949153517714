import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'
import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import {composeSoundWaveformUrl} from '../../composeSoundWaveformUrl'
import slideLayoutDefinitions from '../../slideLayoutDefinitions.js'
import {formatMillisecondDuration} from './formatMillisecondDuration'

const { Handle } = Slider

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="slide-media--sound-player-seekBar-tooltip"
      overlay={`${value}`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}
 

class StaticSlideSoundPlayer extends React.Component{  

  constructor(props) {
    super(props)
    this.playSound=this.playSound.bind(this)
    this.pauseSound=this.pauseSound.bind(this)
    this.startTimer=this.startTimer.bind(this)
    this.seekTo=this.seekTo.bind(this)
    this.restartAudio=this.restartAudio.bind(this)
    this.playbackSkip=this.playbackSkip.bind(this)
    this.stopTimer=this.stopTimer.bind(this)
    this.onAudioEnd=this.onAudioEnd.bind(this)

    const url = `${props.sound.deliveryUrl}.mp3`

    this.audioElement = new Audio(url)
    this.audioElement.addEventListener('ended',this.onAudioEnd)


    this.timer=null


    let waveformImageURL
    if(props.showWaveform){
      waveformImageURL=composeSoundWaveformUrl(props.sound.fileId,props.sound.start,props.sound.end,props.sound.isClipped)
    }

    let duration=props.sound.end-props.sound.start

    this.state = {
      isPlaying:false,
      playerCurrentTime:0,      
      slideWidth:'',
      duration:duration, 
      waveformImageURL:waveformImageURL
    
    }  
}

  componentWillUnmount() {
    this.audioElement.pause()
    this.stopTimer()
  }
 
  onAudioEnd(){
    this.audioElement.currentTime = 0
    this.setState({isPlaying:false,playerCurrentTime:0})
    this.stopTimer()
  }

  updatePlayerCurrentTime(){
    const currentTime=this.audioElement.currentTime
    this.setState({playerCurrentTime:currentTime})
  }

  startTimer(){
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 50)
  }

  stopTimer(){
    clearInterval(this.timer)
    this.timer=null
  }



  playSound(){
    this.setState({isPlaying:true})
    this.startTimer()
    this.audioElement.play()
  }

  pauseSound(){
    this.stopTimer()
    this.setState({isPlaying:false})
    this.audioElement.pause()
  }

  seekTo(seconds){
    this.audioElement.currentTime=seconds
    if(!this.timer){
      this.setState({playerCurrentTime:seconds})
    }
  }

  playbackSkip(skipDirection){ //skip forwards/back 15 seconds
    const currentTime=this.audioElement.currentTime
    let newTime
    if(skipDirection==='forward'){
      newTime=Math.min(currentTime+15,this.state.duration)
    }
    else if(skipDirection==='back'){
      newTime=Math.max(currentTime-15,0)
    }
    this.seekTo(newTime)
  }

  restartAudio(){
    this.audioElement.currentTime=0
  }


  render(){ 

    const {slideWidth, slideTemplate,sound,showWaveform} = this.props
    const {isPlaying,duration,waveformImageURL} = this.state
    
    //const slideWidth = 150
    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale

    const UIInverseScale = inverseScale
    // const ceilingInverseScale = 1.55 

    // const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)

    let mediaWidth

    let widePlayer = false

    const soundContainerHeight = slideLayoutDefinitions.bodySoundPlayerHeight

    
    if((slideTemplate === 'bodyLeftMediaRight') || (slideTemplate === 'bodyRightMediaLeft') || (slideTemplate === 'bodyLeftMediaRightChoicesMedia')){
      mediaWidth = 580 // should come from template definitions or somewhere      
    }
    if(slideTemplate === 'bodyCenterMediaCenter'){
      mediaWidth = 1160 // should come from template definitions or somewhere      
      widePlayer = true      
    }    

    const soundPlayerWidth = mediaWidth

    const scaledSoundPlayeContainerHeight = soundContainerHeight / UIInverseScale
    const scaledSoundPlayerWidth = soundPlayerWidth / UIInverseScale

    // const progress = this.state.playerCurrentTime
    const lengthSeconds = duration // passed through

    let showSeekJumpButtons = false

    if(lengthSeconds > 20){
      showSeekJumpButtons = true
    }

    const soundClipTitle = sound.title

    let displayMillisecondGranularity = true
    let displayHourGranularity=false
    if((this.state.duration) >1){ // if over 1 second 
      displayMillisecondGranularity = false // don't show millisecond granularity
      if(this.state.duration>60*60){
        displayHourGranularity=true
      }
    }



    return (

        <React.Fragment>

          <div style={{transform: `scale(${UIInverseScale})`, width: `${scaledSoundPlayerWidth}px`, height: scaledSoundPlayeContainerHeight ? `${scaledSoundPlayeContainerHeight}px` : null}} className={'slide-media--sound slide-media--sound--staticPlayer ' + (widePlayer ? ' slide-media--sound--widePlayer' : '')}>            

          <div className='slide-media-sound--infoAndTimestampContainer'>              
              
            {!isPlaying &&
            <div className='slide-media-sound--infoAndTimestamp'>
              {/*}
              <div onClick={this.props.showEditSoundModal} className='slide-media-sound--infoAndTimestamp-title'>
                {soundClipTitle}
              </div>
              */}
              <div className='slide-media-sound--infoAndTimestamp-timestamp'>
                {formatMillisecondDuration(duration*1000,displayMillisecondGranularity,displayHourGranularity)}
              </div>
            </div>         
            }

            {isPlaying &&
            <div className='slide-media-sound--infoAndTimestamp'>
              <div className='slide-media-sound--infoAndTimestamp-playingTimestamp'>
                {formatMillisecondDuration(this.state.playerCurrentTime*1000,displayMillisecondGranularity,displayHourGranularity)}
              </div>                              

            </div>         
            }   
          </div>

          <div className={'slide-media--sound-seekBarContainer ' + (isPlaying ? 'slide-media--sound-seekBarContainer--isPlaying' : 'slide-media--sound-seekBarContainer--notPlaying')}>
                                            
              <Slider 
                onChange={(value)=>{this.seekTo(value)}}
                value={this.state.playerCurrentTime} 
                step={0.01}
                min={0} 
                max={lengthSeconds} 
                defaultValue={0} 
                handle={handle}
              />

              
              <div className='slide-media--sound-seekBarContainer-waveformImageContainer'>                                  
                {showWaveform &&
                  <img src={waveformImageURL} />                                  
                }
              </div>
              
            </div>          

            <div className='slide-media--sound-controls'>
                {showSeekJumpButtons &&
                  <button onClick={()=>{this.playbackSkip('back')}} className='slide-media--sound-controls-seekJump'>
                    <EditorIcon name='playbackSeekBack15' />
                  </button>
                }
                <div className='slide-media--sound-controls-centerContainer'>
                  {isPlaying &&
                    <button onClick={this.pauseSound} className='slide-media--sound-controls-playPauseBtn'>
                      <EditorIcon name='playbackPause' /> 
                    </button>
                  }
                  {!isPlaying &&
                    <button onClick={this.playSound} className='slide-media--sound-controls-playPauseBtn'>
                      <EditorIcon name='playbackPlay' /> 
                    </button>
                  }
                </div>
                {showSeekJumpButtons &&
                  <button onClick={()=>{this.playbackSkip('forward')}} className='slide-media--sound-controls-seekJump'>
                    <EditorIcon name='playbackSeekForward15' />
                  </button>
                }
              </div>



            {/*}
            <div className='slide-media--sound-top'>
              <div className='slide-media--sound-top-leftContainer'>
                <EditorIcon name='insertSound' />
              </div>
              <div className='slide-media--sound-top-centerContainer'>
                {progress === 0 &&
                  <div className='slide-media--sound-top-timestamp slide-media--sound-top-timestamp--initialTotal'>
                    {formatDurationTimestamp(duration)}
                  </div>
                }
                {progress > 0 &&
                  <React.Fragment>
                    <div className='slide-media--sound-top-timestamp slide-media--sound-top-timestamp--current'>
                        {formatDurationTimestamp(this.state.playerCurrentTime)}
                    </div>
                    <div className='slide-media--sound-top-timestamp slide-media--sound-top-timestamp--progressDash'>
                      /
                    </div>
                    <div className='slide-media--sound-top-timestamp slide-media--sound-top-timestamp--total'>
                       
                    </div>
                  </React.Fragment>
                }
              </div>
              
              </div>
            </div>
            <div className='slide-media--sound-player'>
              <div className='slide-media--sound-player-leftContainer'>
                {isPlaying &&
                  <button onClick={this.pauseSound} className='slide-media--sound-playPauseBtn'>
                    <EditorIcon name='playbackPause' /> 
                  </button>
                }
                {!isPlaying &&
                  <button onClick={this.playSound} className='slide-media--sound-playPauseBtn'>
                    <EditorIcon name='playbackPlay' /> 
                  </button>
                }
              </div>
              <div className='slide-media--sound-player-centerContainer'>
                
                <div className='slide-media--sound-player-seekBarContainer'>
                  <Slider 
                    onChange={(value)=>{this.seekTo(value)}}
                    value={this.state.playerCurrentTime} 
                    min={0} 
                    max={lengthSeconds} 
                    defaultValue={0} 
                    handle={handle}
                  />          
                </div>

                <div className='slide-media--sound-player-waveformImageContainer'>                                  
                  {showWaveform &&
                    <img src={waveformImageURL} />                                  
                  }
                </div>

              </div>
              <div className='slide-media--sound-player-rightContainer'>

                
                <button onClick={this.restartAudio} className='slide-media--sound-secondaryBtn slide-media--sound-secondaryBtn--restart'>
                  <EditorIcon name='playbackRestart' /> 
                </button>

              </div>
            </div>          
          */}
          </div>
         </React.Fragment>
    )
  }
}


export default StaticSlideSoundPlayer

