import React from 'react';
import YTReady from '../../../../youtubeReady'
import ChoiceVideoControlBar from './ChoiceVideoControlBar'
import slideLayoutDefinitions from '../../slideLayoutDefinitions.js'
import {generateRandomId} from './generateRandomId'



class StaticSlideChoiceVideoPlayer extends React.Component{  


  constructor() {
    super()
    this.loadVideo=this.loadVideo.bind(this)
    this.playVideo=this.playVideo.bind(this)
    this.stopVideo=this.stopVideo.bind(this)
    this.pauseVideo=this.pauseVideo.bind(this)
    this.onPlayerReady=this.onPlayerReady.bind(this)
    this.onStateChange=this.onStateChange.bind(this)
    this.toggleMuteVideo=this.toggleMuteVideo.bind(this)
    this.updatePlayerCurrentTime=this.updatePlayerCurrentTime.bind(this)
    this.startTimer=this.startTimer.bind(this)
    this.stopTimer=this.stopTimer.bind(this)
    this.seekTo=this.seekTo.bind(this)
    this.onClipFinished=this.onClipFinished.bind(this)
    this.onVideoEnd=this.onVideoEnd.bind(this)
    // this.choiceVideoClick=this.choiceVideoClick.bind(this)

    this.playerId=generateRandomId()

     this.state = {      
      playerReady:false,
      isPlaying:false,
      isMuted:false,
      playerCurrentTime:0,
      isDraggingSeekBar:false,
      isBuffering:false
    }

    this.timer=null

}


  componentDidMount(){
    let youtubeReady = YTReady
    youtubeReady.then( (YT) => {
     this.loadVideo(YT)
    })
  }
  componentWillUnmount() {
    this.stopTimer()
    if(this.state.isPlaying){
      this.player.stopVideo()
    }
  }


  loadVideo(YT){
    const { video } = this.props
   
    this.player = new YT.Player(`youtube-player-${this.playerId}`, {
      videoId: video.youtubeId,      
      playerVars: {                                             
        'controls':0, // disable controls
        //'cc_load_policy': 1, // don't know what this does
        'loop': 0, //loop video
        'autoplay': 0, // autoplay                          
        'fs': 0, // show full screen option
        'disablekb': 1, // disable keyboard shortcuts      
        'rel': 0, //either hides related (depreciated) or only shows from same chanel
        'iv_load_policy': 3, // don't show video annotations by default
        'hl':'en', //interface language
        'cc_lang_pref':'en',
        //don't know what these do, from EdPuzzle
        'playsInline':1, 
        'showinfo':0, // undocumented, should hide title but I guess depreciated
        'wmode':'opaque', // undocumented
        'start':(this.props.start),
        'end':(this.props.end+1)
      },
      host: 'http://www.youtube-nocookie.com',     
      events: {
        onReady: this.onPlayerReady,
        onStateChange:this.onStateChange
      },
    });
  };

  onPlayerReady(event){
    const muted=this.player.isMuted()
    this.setState({playerReady:true,isMuted:muted})
  }

  onStateChange(event){
    switch(event.data) {
      case -1://unstarted
        this.stopTimer()
        this.setState({isBuffering:true})        
        break;
      case 0://ended
        this.setState({isBuffering:false,playerCurrentTime:this.props.end})
        this.stopTimer()
        this.onVideoEnd()
        break;
      case 1://playing
        this.startTimer()
        this.setState({isBuffering:false,isPlaying:true})
        this.playVideo()        
        break;
      case 2://paused
        this.stopTimer()
        this.setState({isPlaying:false,isBuffering:false})
        break;
      case 3://BUFFERING
        this.stopTimer()
        this.setState({isBuffering:true})
      default:
        break;
    }
  }


  stopVideo(){
    this.player.stopVideo()
  }

  playVideo(){
    const playerState=this.player.getPlayerState()
    if(playerState===0){
      this.seekTo(this.props.start)
      this.setState({playerCurrentTime:this.props.start})
    }
    this.setState({isPlaying:true})
    this.player.playVideo()
  }

  pauseVideo(){
    this.setState({isPlaying:false})
    this.player.pauseVideo()
  }


  startTimer(){
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 50)
  }

  stopTimer(){
    if(this.timer){
      clearInterval(this.timer)
    }
  }

  updatePlayerCurrentTime(){
     if(!this.state.isBuffering){
      const currentTime=this.player.getCurrentTime()
  		if(currentTime >this.state.playerCurrentTime){
        if(currentTime<this.props.end){
    			this.setState({playerCurrentTime:this.player.getCurrentTime()})
    		}else{
    			this.onClipFinished()
    		}
      }
    }
  }

  onClipFinished(){
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({isPlaying:false,playerCurrentTime:this.props.start})
  }

  onVideoEnd(){
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({playerCurrentTime:this.props.start})
  }

  seekTo(seconds){ 
    this.setState({isBuffering:true,playerCurrentTime:seconds})
    this.player.seekTo(seconds, true)
  }

  toggleMuteVideo(){
    const player=this.player
    if(player.isMuted()){
      player.unMute()
    }else{
      player.mute()
    }
    this.setState({isMuted:!player.isMuted()})
  }

  // choiceVideoClick(){
  //   if(this.state.isPlaying){
  //     this.pauseVideo()
  //   }else{
  //     this.playVideo()
  //   }
  // }


  render(){ 

    const {slideWidth, videoWidth, videoHeight, video, questionChoiceCount} = this.props
    const {isDraggingSeekBar} = this.state
    
    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale


    const mediaWidth = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].width
    const mediaHeight = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].height



    const mediaInternalSidePadding = 5

    const videoPlayerWidth = mediaWidth - mediaInternalSidePadding
    
    

    // refactor for other dimension (i.e. this won't work for body-center-media-center)
    const videoContainerWidth = videoPlayerWidth
    const videoContainerHeight = videoPlayerWidth / videoWidth * videoHeight
        
    const scaledVideoWidth = videoContainerWidth / inverseScale
    const scaledVideoHeight = videoContainerWidth * (videoHeight / videoWidth) / inverseScale


    // prevent and other elements icons getting too big
    const ceilingInverseScale = 1.55 

    const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)

 
    const duration=video.end-video.start
    

    // easier to just set custom top positions
    let topVideoPosition = 48
    let heightControlBar = mediaHeight - videoContainerHeight - topVideoPosition 
    let showDarkTopControls = false

    if(questionChoiceCount === 3){
      topVideoPosition = 0
      heightControlBar = mediaHeight - videoContainerHeight - topVideoPosition
      showDarkTopControls = true
      // showDarkTopControls = false
    }
    if(questionChoiceCount === 2){
      topVideoPosition = 0
      heightControlBar = 50
      showDarkTopControls = true
    }    

    
    const videoTitle = video.title

	  return (

	  		<div onClick={(e)=>{e.stopPropagation()}} onMouseDown={(e)=>{e.stopPropagation()}} onMouseUp={(e)=>{e.stopPropagation()}} className={'slide-choice-media slide-choice-media--video ' + (isDraggingSeekBar ? 'slide-choice-media--video--draggingSeekbar':'')}>                      

          <div style={{width: `${videoContainerWidth}px`, height:`${videoContainerHeight}px`, marginTop: '0px', top: `${topVideoPosition}px`}} className='slide-choice-media--video-videoContainer'>
            <div style={{top:`${150 * -1 * inverseScale}px`, transform: `scale(${inverseScale})`, width: `${scaledVideoWidth}px`, height: `${scaledVideoHeight + 300}px`}} id={`youtube-player-${this.playerId}`} className="slide-choice-media--video-video" />                        
          </div>

        
          <div style={{transform: `scale(${UIInverseScale})`, height:`${heightControlBar / UIInverseScale}px`, width: `${videoContainerWidth / UIInverseScale}px`}} className='slide-media-choice--video-controlBarContainer'>
              
              {this.state.playerReady &&
                <ChoiceVideoControlBar 
                  playVideo={this.playVideo}
                  pauseVideo={this.pauseVideo}
                  YTPlayer={this.player}
                  clipStartTime={video.start}
                  clipEndTime={video.end}
                  duration={duration}
                  isPlaying={this.state.isPlaying}
                  isMuted={this.state.isMuted}
                  toggleMuteVideo={this.toggleMuteVideo}
                  playerCurrentTime={this.state.playerCurrentTime}
                  seekTo={this.seekTo}
                  showInfo={true}
                  overlayMode={questionChoiceCount === 2}
                  videoTitle={videoTitle}
                  onBeforeChange={()=>{this.setState({isDraggingSeekBar:true})}}
                  onAfterChange={()=>{this.setState({isDraggingSeekBar:false})}}                  
                />
              }

          </div>                                    
          
          
          

        </div>
        

	     	 
	  )
	}
}


export default StaticSlideChoiceVideoPlayer



