import React from 'react'
import moment from 'moment'
import { getCalibratedCurrentTime } from '../utils/getCalibratedCurrentTime'

class NowPlayingCountdown extends React.Component {
  constructor() {
    super()
    this.startCountdown = this.startCountdown.bind(this)
    this.stopCountdown = this.stopCountdown.bind(this)
    this.countdown = null

    this.state = {
      countdownTime: 0,
    }
  }

  componentDidMount() {
    this.startCountdown()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.countdownEndsAt &&
      !moment(nextProps.countdownEndsAt).isSame(moment(this.props.countdownEndsAt))
    ) {
      this.stopCountdown()
      this.startCountdown(nextProps.countdownEndsAt)
    }
    if (!nextProps.countdownEndsAt) {
      this.stopCountdown()
    }
  }

  componentWillUnmount() {
    this.stopCountdown()
  }

  startCountdown(countdownEnds) {
    const countdownEndsAt = countdownEnds || this.props.countdownEndsAt
    const nowDate = getCalibratedCurrentTime()
    const countdownTime = Math.max(moment(countdownEndsAt) - nowDate, 0)
    this.setState({ countdownTime })
    this.countdown = setInterval(() => {
      if (this.state.countdownTime > 10) {
        const nowDate = getCalibratedCurrentTime()
        this.setState({
          countdownTime: Math.max(moment(countdownEndsAt) - nowDate, 0),
        })
      } else {
        this.stopCountdown()
        this.props.onCountdownFinish()
      }
    }, 10)
  }

  stopCountdown() {
    clearInterval(this.countdown)
  }

  render() {
    const { countdownTime } = this.state
    const seconds = (`0${Math.floor(countdownTime / 1000) % 60}`).slice(-2)
    const minutes = (`0${Math.floor(countdownTime / 60000) % 60}`).slice(-2)
    return (
      <React.Fragment>
        <span className={`nowPlaying-countdownTimer-minutes${minutes > 0 ? ' nowPlaying-countdownTimer-minutes--nonZeroMinutes ' : ' nowPlaying-countdownTimer-minutes--zeroMinutes'}`}>
          {minutes}
          :
        </span>
        {seconds}
      </React.Fragment>
    )
  }
}

export default NowPlayingCountdown
