import React from 'react'
import Confetti from 'react-confetti'
import { Transition, Spring, animated } from 'react-spring/renderprops'

const LABEL_ENTER_INSET=1000//time in ms
const LABEL_EXIT_INSET=300//time in ms



class NowPlayingPerfectScoreCelebration extends React.Component{

	constructor(){
		super()
		this.state=({
			showLabel: false
		})
		this.labelInTimeout=null
		this.labelOutTimeout=null

	}

 

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.showPerfectScoreCelebration && !this.props.showPerfectScoreCelebration){
			this.labelInTimeout=setTimeout(() => {
	            this.setState({
	            showLabel:true
	          })
	        }, LABEL_ENTER_INSET)

			const timerLength=nextProps.totalDisplayTime - (LABEL_EXIT_INSET)
			this.labelOutTimeout=setTimeout(() => {
	            this.setState({
	            showLabel:false
	          })
	        }, timerLength)
		}

		if(this.props.showPerfectScoreCelebration && !nextProps.showPerfectScoreCelebration){
			this.setState({showLabel:false})
			if (this.labelInTimeout) {
			  clearTimeout(this.labelInTimeout)
			  this.labelInTimeout = null
			}
			if (this.labelOutTimeout) {
			  clearTimeout(this.labelOutTimeout)
			  this.labelOutTimeout = null
			}
		}
	}





	render(){	

		const {showPerfectScoreCelebration,hidePerfectScoreCelebration} = this.props
		const {showLabel}=this.state

		return(
			<React.Fragment>
				<Transition
					native
					items={showPerfectScoreCelebration}
					from={{opacity: 0}}
					enter={{opacity: 1}}
					leave={{opacity: 0}}
				>
					{showPerfectScoreCelebration => 
						showPerfectScoreCelebration && (
							props => 
							<animated.div style={props} className='nowPlaying-perfectScoreCelebrationContainer' onClick={hidePerfectScoreCelebration}>
								<Confetti 
									width={this.props.windowWidth}
									height={this.props.windowHeight}
									recycle={false}									
									numberOfPieces={300}
									gravity={0.15}
					 				//confettiSource={{x: 0, y: 0, w: this.props.windowWidth, h: this.props.windowHeight}}					 
								/>

							</animated.div>
						)
					}				
					
				</Transition>



				
				<Transition
					native
					config={{ tension: 200, friction: 8}}
					items={showLabel}
					//delay={LABEL_ENTER_INSET}
					from={{opacity: 0, transform: `scale(${0.5})`}}
					enter={{opacity: 1, transform: `scale(${1}px)`}}
					leave={{opacity: -1, transform: `scale(${0.5}px)`}}
				>
				{showLabel => 
					showLabel  && (
						props => 
						<animated.div style={props} className='nowPlaying-perfectScoreCelebration-label' onClick={hidePerfectScoreCelebration}>
				       
				       <Spring config={{ tension: 170, friction: 26}} from={{score: 0}} to={{ score: 100 }} >									
				       	{props => 
									<React.Fragment>
										{Math.round(props.score)}%
									</React.Fragment>
								}						     						
				      </Spring>

						</animated.div>

					)

				}				
				
			</Transition>		
			
	
				
			</React.Fragment>
		)
	}
}





export default NowPlayingPerfectScoreCelebration



