import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import find from 'lodash/find'
import { connect } from 'react-redux'
import { Transition } from 'react-spring/renderprops'
import NowPlayingPage from '../components/NowPlayingPage'
import { addStudentSetPollResponse, addStudentPollResponse } from '../actions/student'
import NowPlayingPerfectScoreCelebration from '../components/NowPlayingPerfectScoreCelebration'
import calculateCorrectPercent from '../utils/calculateCorrectPercent'

const PERFECT_SCORE_DISPLAY_TIME = 6000// time in ms

// For displaying confetti celebration
const perfectScoreIsDisplayed = (props) => {
  if (
    props.nowPlaying &&
    !props.nowPlaying.survey &&
    props.nowPlaying.revealAnswer &&
    props.nowPlaying.showGraph &&
    !props.nowPlaying.scanning
  ) {
    const correctPercent = calculateCorrectPercent(props.nowPlaying)
    if (correctPercent === 100) {
      return true
    } return false
  }
  return false
}

class NowPlayingContainer extends Component {
  constructor() {
    super()
    this.selectChoice = this.selectChoice.bind(this)
    this.showPerfectScoreCelebration = this.showPerfectScoreCelebration.bind(this)
    this.hidePerfectScoreCelebration = this.hidePerfectScoreCelebration.bind(this)

    this.state = ({
      studentResponses: {},
      showPerfectScoreCelebration: false,
    })
    this.timeout = null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.visible &&
      perfectScoreIsDisplayed(nextProps) &&
      !perfectScoreIsDisplayed(this.props)
    ) {
      this.showPerfectScoreCelebration()
    } else if (!perfectScoreIsDisplayed(nextProps) && this.state.showPerfectScoreCelebration) {
      this.hidePerfectScoreCelebration()
    }
  }

  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('Student Now Playing')
    }
  }

  showPerfectScoreCelebration() {
    this.setState({ showPerfectScoreCelebration: true })
    this.timeout = setTimeout(() => {
      this.setState({
        showPerfectScoreCelebration: false,
      })
    }, PERFECT_SCORE_DISPLAY_TIME)
  }

  hidePerfectScoreCelebration() {
    this.setState({ showPerfectScoreCelebration: false })
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  selectChoice(choice) {
    const setPollId = this.props.nowPlaying.setPoll
    const setPollPollId = this.props.nowPlaying.setPollPoll
    const questionPollId = this.props.nowPlaying.currentPoll
    let responseIdentifier
    if (setPollId) {
      responseIdentifier = `${setPollId}-${setPollPollId}`
    } else {
      responseIdentifier = questionPollId
    }
    const { studentResponses } = this.state
    const nowDate = new Date()
    studentResponses[responseIdentifier] = {
      response: choice,
      captured: nowDate,
    }
    this.setState({ studentResponses })
    if (setPollId) {
      this.props.addStudentSetPollResponse(
        setPollId,
        setPollPollId,
        choice,
        this.props.studentId,
        this.props.section.id,
      )
    } else if (questionPollId) {
      this.props.addStudentPollResponse(
        questionPollId,
        choice,
        this.props.studentId,
        this.props.section.id,
      )
    }
  }

  render() {
    let selectedChoice
    if (this.props.nowPlaying) {
      const setPollId = this.props.nowPlaying.setPoll
      const setPollPollId = this.props.nowPlaying.setPollPoll
      const questionPollId = this.props.nowPlaying.currentPoll
      let responseIdentifier
      if (setPollId) {
        responseIdentifier = `${setPollId}-${setPollPollId}`
      } else {
        responseIdentifier = questionPollId
      }
      const studentResponse = this.state.studentResponses[responseIdentifier]
      if (studentResponse) {
        selectedChoice = studentResponse.response
      }
    }
    const { visible } = this.props
    return (
      <Transition
        items={visible}
        from={{ opacity: 0, transform: 'translateY(14px)' }}
        enter={{ opacity: 1, transform: 'translateY(0px)' }}
        leave={{ opacity: 0, transform: 'translateY(14px)' }}
        config={{ tension: 210, friction: 18 }}
      >
        {(visible) => visible && ((props) => (
          <div className="nowPlayingContainer" style={props}>
            <NowPlayingPage
              key={this.props.nowPlaying ? `${this.props.nowPlaying.controlled}` : ''}
              nowPlaying={this.props.nowPlaying}
              selectChoice={(choice) => { this.selectChoice(choice) }}
              selectedChoice={selectedChoice}
              closeNowPlaying={this.props.closeNowPlaying}
              activeSection={this.props.section}
              studentFirstName={this.props.studentFirstName}
            />

            <NowPlayingPerfectScoreCelebration
              showPerfectScoreCelebration={this.state.showPerfectScoreCelebration}
              windowWidth={window.width}
              windowHeight={window.height}
              totalDisplayTime={PERFECT_SCORE_DISPLAY_TIME}
              hidePerfectScoreCelebration={this.hidePerfectScoreCelebration}
            />
          </div>
        ))}
      </Transition>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const section = find(state.sections, { id: ownProps.sectionId })
  let nowPlaying = null
  if (section) {
    nowPlaying = section.nowPlaying
  }

  let studentId
  let studentFirstName
  if (section && state.session) {
    state.session.authorizations.forEach((authorization) => {
      if (authorization.section.id === section.id) {
        const { student } = authorization
        studentId = student.id
        studentFirstName = student.firstName
      }
    })
  }

  return {
    section,
    nowPlaying,
    session: state.session,
    studentId,
    studentFirstName,
  }
}

export default withRouter(connect(
  mapStateToProps,
  { addStudentSetPollResponse, addStudentPollResponse },
)(NowPlayingContainer))
