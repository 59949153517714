import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { addAuthorizationToSession, deleteAuthorizationFromSession, clearAllSessionData } from '../actions/session'
import { fetchSection } from '../actions/sections'
import HomePage from '../components/HomePage'

class HomePageContainer extends Component {
  constructor() {
    super()
    this.addStudentAuthorization = this.addStudentAuthorization.bind(this)
    this.sessionHasAuthorizationForCode = this.sessionHasAuthorizationForCode.bind(this)
    this.deleteAuthorizationFromSession = this.deleteAuthorizationFromSession.bind(this)
  }

  componentDidMount() {
    if (
      !this.props.pendingAuthorization &&
      this.props.match.params.studentCode &&
      this.props.session
    ) {
      this.addStudentAuthorization(this.props.match.params.studentCode)
    }
    if (window.analytics) {
      let authCount = 0
      if (this.props.session) {
        authCount = this.props.session.authorizations.length
      }
      window.analytics.page('Student Home', { authorizationsCount: authCount })
    }
  }

  componentDidUpdate() {
    if (
      !this.props.pendingAuthorization &&
      this.props.match.params.studentCode &&
      this.props.session
    ) {
      this.addStudentAuthorization(this.props.match.params.studentCode)
    }
  }

  sessionHasAuthorizationForCode(code) {
    const { authorizations } = this.props.session
    let hasAuthorization = false
    authorizations.forEach((authorization) => {
      if (authorization.student.code === code) {
        hasAuthorization = true
      }
    })
    return hasAuthorization
  }

  deleteAuthorizationFromSession(studentId, sectionId) {
    this.props.clearNPTimer()
    this.props.deleteAuthorizationFromSession(this.props.session.id, studentId, sectionId)
  }

  addStudentAuthorization() {
    const { studentCode } = this.props.match.params
    if (!this.sessionHasAuthorizationForCode(studentCode)) {
      this.props.addStudentAuthorization(studentCode)
    } else {
      this.props.history.push('/')
    }
  }

  render() {
    return (
      <HomePage
        pendingAuthorization={this.props.pendingAuthorization}
        session={this.props.session}
        sections={this.props.sections}
        deleteAuthorizationFromSession={this.deleteAuthorizationFromSession}
        openNowPlaying={this.props.openNowPlaying}
        countdownSectionId={this.props.countdownSectionId}
        activeCountdownSeconds={this.props.activeCountdownSeconds}
        clearAllSessionData={this.props.clearAllSessionData}
        editAuthorizationsMode={this.props.editAuthorizationsMode}
        toggleEditAuthorizationsMode={this.props.toggleEditAuthorizationsMode}
      />
    )
  }
}

export default withRouter(connect(
  (state) => ({
    session: state.session,
    sections: state.sections,
  }),
  {
    addAuthorizationToSession,
    fetchSection,
    deleteAuthorizationFromSession,
    clearAllSessionData,
  },
)(HomePageContainer))
