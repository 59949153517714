import React, { Component } from 'react'
import find from 'lodash/find'
import HomePageSectionCard from './HomePageSectionCard'

class HomePageSectionGrid extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.sections !== nextProps.sections && nextProps.pendingAuthorization) {
      return false
    }
    return true
  }

  render() {
    const { authorizations, pendingAuthorization, sections } = this.props
    return (
      <div className="homePage-sectionGrid">
        {authorizations.map((authorization) => {
          if (
            pendingAuthorization &&
            pendingAuthorization.student &&
            pendingAuthorization.student.id === authorization.student.id
          ) {
            return null
          }
          const section = find(sections, { id: authorization.section.id })
          let countDownActive = false
          if (section && section.id === this.props.countdownSectionId) {
            countDownActive = true
          }
          return (
            <HomePageSectionCard
              openNowPlaying={this.props.openNowPlaying}
              key={authorization.section.id}
              sectionName={authorization.section.name}
              sectionColor={authorization.section.color}
              sectionTeacherName={authorization.section.teacherDisplayName}
              studentFirstName={authorization.student.firstName}
              sectionPreAuthorized
              section={authorization.section}
              editAuthorizationsMode={this.props.editAuthorizationsMode}
              sectionActiveNowPlaying={section && section.nowPlaying}
              fullSectionObject={section}
              deleteAuthorizationFromSession={() => {
                this.props.deleteAuthorizationFromSession(
                  authorization.student.id,
                  authorization.section.id,
                )
              }}
              countDownActive={countDownActive}
              countDownSeconds={this.props.activeCountdownSeconds}
            />
          )
        })}
        {pendingAuthorization && (
        <HomePageSectionCard
          sectionActiveNowPlaying={
            pendingAuthorization.section &&
            find(sections, { id: pendingAuthorization.section.id }) &&
            find(sections, { id: pendingAuthorization.section.id }).nowPlaying
          }
          sectionName={
            pendingAuthorization.section && pendingAuthorization.section.name
          }
          sessionActiveNowPlaying={
            pendingAuthorization.section && pendingAuthorization.section.nowPlaying
          }
          sectionColor={
            pendingAuthorization.section && pendingAuthorization.section.color
          }
          sectionTeacherName={
            pendingAuthorization.section && pendingAuthorization.section.teacherDisplayName
          }
          studentFirstName={
            pendingAuthorization.student && pendingAuthorization.student.firstName
          }
          sectionPreAuthorized={false}
          authorizationRequestSent={pendingAuthorization.authorizationRequestSent}
          authorizationRequestStatus={pendingAuthorization.authorizationRequestStatus}
          deleteAuthorizationFromSession={this.props.deleteAuthorizationFromSession}
        />
        )}
      </div>
    )
  }
}

export default HomePageSectionGrid
