import React from 'react'
import { Spring, animated } from 'react-spring/renderprops' 
import slideLayoutDefinitions from '../../slideLayoutDefinitions.js'

class StaticSlideChoiceZoomableFitImage extends React.Component{
	
	render(){	

		const {image, slideTemplate, choiceIndex, UIInverseScale, slideScale, choiceMediaZoomed, activeZoomedChoiceIndex, mediaChoiceWidth} = this.props  	
    
    //
    // FIND SIZE OF IMAGE TO FIT  CREATE DIV FOR MEASURING
  	const displayImageWidth = image.deliveryDimensions.w
    const displayImageHeight = image.deliveryDimensions.h
    const displayImageAspect = displayImageWidth / displayImageHeight

    const imageContainerWidth = mediaChoiceWidth - 6 // bring in from definitions with some 
    const imageContainerHeight = 284 // refactor template definitions  
    const containerAspect = imageContainerWidth / imageContainerHeight

    let fitImageWidth
    let fitImageHeight     
    
    if(displayImageAspect > containerAspect){ // image is more landscape than media choice container
      fitImageWidth = imageContainerWidth
      fitImageHeight = fitImageWidth / displayImageAspect
    }

    if(displayImageAspect < containerAspect){ // image is more portrait than media choice container
      fitImageHeight = imageContainerHeight
      fitImageWidth = fitImageHeight * displayImageAspect
    }

    // center the image (could be done otherways)
    const marginTop = (imageContainerHeight - fitImageHeight) / 2
    const marginLeft = (imageContainerWidth - fitImageWidth) / 2



			return(							
					<div id={`choice${choiceIndex}FitImageZoomable`} style={{marginTop: `${marginTop}px`, marginLeft: `${marginLeft}px`, width: `${fitImageWidth}px`, height: `${fitImageHeight}px`}}  className='slide-choice-media--image-fitImageZoomable'>
            
          </div>		                    								
				)		
			}	
		}


export default StaticSlideChoiceZoomableFitImage
