import React from 'react'
import slideLayoutDefinitions from '../../slideLayoutDefinitions.js'

class StaticSlideFitImage extends React.Component{


  render(){
    const {image, slideTemplate, questionMediaDynamicHeight} = this.props

    const displayImageWidth = image.deliveryDimensions.w
    const displayImageHeight = image.deliveryDimensions.h
    const displayImageAspect = displayImageWidth / displayImageHeight

    const imageContainerWidth = slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].width
    let imageContainerHeight = slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].height

    if(slideTemplate === 'bodyCenterMediaCenter'){
      imageContainerHeight = questionMediaDynamicHeight
    }

    const containerAspect = imageContainerWidth / imageContainerHeight    
    
    let fitImageWidth
    let fitImageHeight     
    
    if(displayImageAspect > containerAspect){ // image is more landscape than media choice container
      fitImageWidth = imageContainerWidth
      fitImageHeight = fitImageWidth / displayImageAspect
    }

    if(displayImageAspect < containerAspect){ // image is more portrait than media choice container
      fitImageHeight = imageContainerHeight
      fitImageWidth = fitImageHeight * displayImageAspect
    }

    // POSITIONING WITHIN CONTAINER

    let imageLayoutStyle

    if(slideTemplate === 'bodyLeftMediaRight' || slideTemplate === "bodyLeftMediaRightChoicesMedia"){
      imageLayoutStyle = {top: '0px', right: '0px'}
    }
    if(slideTemplate === 'bodyRightMediaLeft'){
      imageLayoutStyle = {top: '0px', left: '0px'}
    }
    if(slideTemplate === 'bodyCenterMediaCenter'){
      imageLayoutStyle = {top: '0px', margin: 'auto', left: '0px', right:'0px'}
    }    

        
    return(             
      <div id="fitImageZoomable" style={{width: `${fitImageWidth}px`, height: `${fitImageHeight}px`, ...imageLayoutStyle}}  className='slide-media--imageCover slide-media--imageCover--fit'>
        {/*
          image.deliveryDimensions.w: {image.deliveryDimensions.w}<br/>
          image.deliveryDimensions.h: {image.deliveryDimensions.h}<br/>
          Image Container Width: {imageContainerWidth}<br/>
          Image Container Height: {imageContainerHeight}<br/>
          Fit Image Width: {fitImageWidth}<br/>
          Fit Image Width: {fitImageHeight}<br/>
        */}
      </div>
    )   
  } 
}


export default StaticSlideFitImage
