import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Keyframes, Spring, animated } from 'react-spring/renderprops'
import delay from 'delay'

// sectionPreAuthorized:true/false
// authorizationRequestSent
// authorizationRequestStatus:null/success/failure

class HomePageSectionCard extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.handleOnClickDeleteButton = this.handleOnClickDeleteButton.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    if (!nextProps.sectionPreAuthorized) {
      if (nextProps.authorizationRequestStatus !== this.props.authorizationRequestStatus) {
        return true
      }
      return false
    }
    if (this.props.countDownActive === true && nextProps.countDownActive === false) {
      return false
    } return true
  }

  handleOnClickDeleteButton(e) {
    e.stopPropagation()
    this.props.deleteAuthorizationFromSession()
  }

  onClick() {
    if (this.props.section) {
      this.props.openNowPlaying(this.props.section.id)
    }
  }

  render() {
    const {
      sectionName,
      sectionColor,
      sectionTeacherName,
      studentFirstName,
      sectionPreAuthorized,
      sectionActiveNowPlaying,
      countDownActive,
      countDownSeconds,
      fullSectionObject,
    } = this.props
    const {
      authorizationRequestSent,
      authorizationRequestStatus,
    } = this.props
    const cardHeight = 250
    const initialRegisteringHeight = 60
    const authorizationSuccess = authorizationRequestStatus === 'success'
    const authorizationFailure = authorizationRequestStatus === 'failure'
    const Card = Keyframes.Spring(async (next) => {
      // REQUEST SENT
      if (
        authorizationRequestSent &&
        !sectionPreAuthorized &&
        !authorizationSuccess &&
        !authorizationFailure
      ) {
        await next({
          opacity: 0,
          top: `${cardHeight / 2}px`,
          height: 0,
          config: { duration: 50 },
        })
        await next({
          opacity: 1,
          top: `${(cardHeight - initialRegisteringHeight) / 2 - 20}px`,
          height: initialRegisteringHeight,
        })
      }

      // SUCCESS: FORWARD
      if (authorizationSuccess && !sectionPreAuthorized) {
        await next({
          from: { opacity: 1, top: `${(cardHeight - initialRegisteringHeight) / 2 - 20}px`, height: initialRegisteringHeight },
        })
        await delay(900)
        await next({
          top: '0%',
          height: cardHeight,
        })
      }

      // FAILURE: FORWARD
      if (authorizationFailure && !sectionPreAuthorized) {
        await next({
          from: { opacity: 1, top: `${(cardHeight - initialRegisteringHeight) / 2 - 20}px`, height: initialRegisteringHeight },
          top: `${(cardHeight - 120) / 2 - 20}px`,
          height: 120,
        })
      }
      // PREAUTHORIZED
      if (sectionPreAuthorized) {
        await next({
          top: '0%',
          height: cardHeight,
        })
      }
    })

    const RegisterProgressBarBar = Keyframes.Spring(async (next) => {
      // REQUEST SENT: FORWARD
      if (
        authorizationRequestSent &&
        !sectionPreAuthorized &&
        !authorizationSuccess &&
        !authorizationFailure
      ) {
        await delay(200)
        await next({
          width: '0%',
        })
        await next({
          width: '68%',
        })
      }

      // SUCCESS: FORWARD
      if (authorizationSuccess && !sectionPreAuthorized) {
        await next({
          width: '0%',
        })
        await next({
          width: '100%',
        })
      }

      // FAILURE: FORWARD
      if (authorizationFailure && !sectionPreAuthorized) {
        await next({
          from: { width: '80%' },
          width: '0%',
        })
      }
    })

    const RegisterProgressBar = Keyframes.Spring(async (next) => {
      // REQUEST SENT: FORWARD
      if (
        authorizationRequestSent &&
        !sectionPreAuthorized &&
        !authorizationSuccess &&
        !authorizationFailure
      ) {
        await delay(50)
        await next({
          opacity: '0%',
          config: { duration: 200 },
        })
        await next({
          opacity: '100%',
        })
      }
      // SUCCESS: FORWARD
      if (authorizationSuccess && !sectionPreAuthorized) {
        await next({
          from: { opacity: '100%' },
        })
        await delay(500)
        await next({
          opacity: '0%',
        })
      }
    })

    const TopSectionRegister = Keyframes.Spring(async (next) => {
      // SUCCESS: FORWARD
      if (authorizationSuccess && !sectionPreAuthorized) {
        await next({
          from: { top: `-${cardHeight}px` },
        })
        await delay(1200)
        await next({
          top: '0px',
        })
      }

      // FAILURE: FORWARD
      if (sectionPreAuthorized) {
        await next({
          top: '0px',
        })
      }
    })

    const BottomSectionRegister = Keyframes.Spring(async (next) => {
      // SUCCESS: FORWARD
      if (authorizationSuccess && !sectionPreAuthorized) {
        await next({
          from: { bottom: '-40px' },
        })
        await delay(2000)
        await next({
          bottom: '0px',
        })
      }

      // FAILURE: FORWARD
      if (sectionPreAuthorized) {
        await next({
          bottom: '0px',
        })
      }
    })

    const RegisterErrorMessage = Keyframes.Spring(async (next) => {
      // FAILURE: FORWARD
      if (authorizationFailure && !sectionPreAuthorized) {
        await next({
          from: { opacity: 0 },
          opacity: 0.01,

        })
        await delay(500)
        await next({
          opacity: 1,
        })
      }
    })

    return (
      <div className={`homePage-sectionCardContainer ${sectionActiveNowPlaying ? 'sectionCardContainer--nowPlayingActive ' : ''}${authorizationRequestSent ? 'homePage-sectionCardContainer--active' : ''}${sectionColor ? ` homePage-sectionCard--color--${sectionColor}` : ''}${authorizationFailure ? ' homePage-sectionCardContainer--failure' : ''}${this.props.editAuthorizationsMode ? ' homePage-sectionCardContainer--showDeauthButtons ' : ' homePage-sectionCardContainer--dontShowDeauthButtons '}`} onClick={this.onClick}>
        <Card native>
          {(props) => (
            <animated.div style={{ position: 'relative', ...props }} className="homePage-sectionCard">

              <RegisterProgressBar native>
                {(props) => (
                  <animated.div className="homePage-sectionCard-registerBarContainer" style={{ ...props }}>
                    <div className="homePage-sectionCard-registerBar-label">
                      joining class

                      {/* {sectionPreAuthorized? 'pre-authorized' : ''}
                        {authorizationRequestSent? 'request sent' : ''}
                        {authorizationRequestStatus? `status: ${authorizationRequestStatus}` : ''}
                      */}
                    </div>
                    <div className="homePage-sectionCard-registerBar">
                      <RegisterProgressBarBar native>
                        {(props) => (<animated.div className="homePage-sectionCard-registerBar-bar" style={{ position: 'absolute', ...props }} />)}
                      </RegisterProgressBarBar>
                      <div className="homePage-sectionCard-registerBar-track" />
                    </div>
                  </animated.div>
                )}
              </RegisterProgressBar>

              <RegisterErrorMessage native>
                {(props) => (
                  <animated.div className="homePage-sectionCard-errorMessage" style={{ position: 'relative', ...props }}>
                    <div className="homePage-sectionCard-errorMessage-iconContainer">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M19.511,17.98L10.604,1.348C10.48,1.133,10.25,1,10,1C9.749,1,9.519,1.133,9.396,1.348L0.49,17.98  c-0.121,0.211-0.119,0.471,0.005,0.68C0.62,18.871,0.847,19,1.093,19h17.814c0.245,0,0.474-0.129,0.598-0.34  C19.629,18.451,19.631,18.191,19.511,17.98z M11,17H9v-2h2V17z M11,13.5H9V7h2V13.5z" /></svg>
                    </div>
                    {
                      /* eslint-disable max-len */
                      /*
                        <div className='homePage-sectionCard-errorMessage-close'>
                          <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.8354471,6.73149271 C32.3906857,5.17625413 32.3824408,2.69209514 30.8451728,1.15482716 C29.2971873,-0.393158373 26.8111466,-0.378086464 25.2685073,1.16455289 L16,10.4330602 L6.73149271,1.16455289 C5.17625413,-0.390685684 2.69209514,-0.382440823 1.15482716,1.15482716 C-0.393158373,2.70281269 -0.378086464,5.18885335 1.16455289,6.73149271 L10.4330602,16 L1.16455289,25.2685073 C-0.390685684,26.8237459 -0.382440823,29.3079049 1.15482716,30.8451728 C2.70281269,32.3931584 5.18885335,32.3780865 6.73149271,30.8354471 L16,21.5669398 L25.2685073,30.8354471 C26.8237459,32.3906857 29.3079049,32.3824408 30.8451728,30.8451728 C32.3931584,29.2971873 32.3780865,26.8111466 30.8354471,25.2685073 L21.5669398,16 L30.8354471,6.73149271 Z"></path>
                          </svg>
                        </div>
                      */
                      /* eslint-enable max-len */
                    }
                    <div className="homePage-sectionCard-errorMessage-primaryLabel">
                      {this.props.match.params.studentCode}
                      {' '}
                      <br />
                      is an invalid token
                    </div>
                    <div className="homePage-sectionCard-errorMessage-description">
                      please check your token link
                      {' '}
                      <br />
                      and try again.
                    </div>
                  </animated.div>
                )}
              </RegisterErrorMessage>

              <TopSectionRegister native>
                {(props) => (
                  <animated.div className="homePage-sectionCard-top" style={{ position: 'absolute', ...props }}>
                    <div className="homePage-sectionCard-top-sectionName">
                      {sectionName}
                    </div>
                    <div className="homePage-sectionCard-top-teacherName">
                      {sectionTeacherName}
                    </div>
                    <div className="homePage-sectionCard-top-studentName">
                      {studentFirstName}
                    </div>
                  </animated.div>
                )}
              </TopSectionRegister>

              <BottomSectionRegister native>
                {(props) => (
                  <animated.div className="homePage-sectionCard-bottom" style={{ position: 'absolute', ...props }}>

                    {!sectionActiveNowPlaying && fullSectionObject && (
                    <div className="homePage-sectionCard-statusPill homePage-sectionCard-statusPill--offline ">
                      Offline
                    </div>
                    )}

                    {sectionActiveNowPlaying && fullSectionObject && (
                    <div className={`homePage-sectionCard-statusPill homePage-sectionCard-statusPill--nowPlaying ${countDownActive ? 'homePage-sectionCard-statusPill--nowPlaying--countDown ' : ' homePage-sectionCard-statusPill--nowPlaying--pulse'}`}>
                      <div className="homePage-sectionCard-statusPill--nowPlaying-pulse" />
                      Now Playing
                    </div>
                    )}
                  </animated.div>
                )}
              </BottomSectionRegister>

            </animated.div>
          )}
        </Card>

        <Spring
          config={{ tension: 170, friction: 26 }}
          to={{
            opacity: countDownActive ? 0.9 : 0,
            translateY: countDownActive ? 0 : -50,
          }}
        >
          { (props) => (
            <div onClick={this.onClick} style={{ opacity: `${props.opacity}`, transform: `translateY(${props.translateY}px)` }} className="homePage-sectionCard-countDownPop">
              <div className="homePage-sectionCard-countDownPop-label">
                Joining in
              </div>
              <div className={`homePage-sectionCard-countDownPop-number${countDownSeconds === 3 ? ' homePage-sectionCard-countDownPop-number--active ' : ' homePage-sectionCard-countDownPop-number--inactive '}`}>
                3
              </div>
              <div className={`homePage-sectionCard-countDownPop-number${countDownSeconds === 2 ? ' homePage-sectionCard-countDownPop-number--active ' : ' homePage-sectionCard-countDownPop-number--inactive '}`}>
                2
              </div>
              <div className={`homePage-sectionCard-countDownPop-number${countDownSeconds === 1 ? ' homePage-sectionCard-countDownPop-number--active ' : ' homePage-sectionCard-countDownPop-number--inactive '}`}>
                1
              </div>
            </div>
          )}
        </Spring>

        <Spring
          config={{ tension: 170, friction: 26 }}
          to={{
            opacity: this.props.editAuthorizationsMode ? 0.7 : 0,
            translateY: this.props.editAuthorizationsMode ? 0 : -50,
          }}
        >
          { (props) => (
            <button onClick={this.handleOnClickDeleteButton} style={{ opacity: `${props.opacity}`, transform: `translateY(${props.translateY}px)` }} className="homePage-sectionCard-logOutButton">
              <div className="homePage-sectionCard-logOutButton-label">
                Log Out
              </div>
            </button>
          )}
        </Spring>

      </div>

    )
  }
}

export default withRouter(HomePageSectionCard)
