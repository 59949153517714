import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
  switch (action.type) {
    case 'FETCH_SECTION_SUCCESS':
    case 'SECTION_RECEIVED': {
      const fetchedSectionId = action.section.id
      let indexOfSectionFetched = findIndex(state, (section) => section.id === fetchedSectionId)
      if (indexOfSectionFetched === -1) {
        indexOfSectionFetched = state.length
      }
      return [
        ...state.slice(0, indexOfSectionFetched),
        action.section,
        ...state.slice(indexOfSectionFetched + 1),
      ]
    }
    case 'CLEAR_ALL_DATA':
      return []
    default:
      return state
  }
}
