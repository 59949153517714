function getInitialState() {
  const session = JSON.parse(localStorage.getItem('session'))
  if (session) {
    return session
  } return null
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case 'CREATE_SESSION_SUCESS':
      return action.response
    case 'UPDATE_SESSION_SUCESS':
      return action.response
    case 'CLEAR_ALL_DATA':
      return null
    default:
      return state
  }
}
