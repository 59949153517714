import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'



class StaticSlideChoiceImage extends React.Component{

	constructor(props){
		super(props)
		this.onLoad=this.onLoad.bind(this)
	}


	onLoad(){
		if(this.props.choiceMediaPromiseResolve){
			console.log(`choice ${this.props.choiceLetter} image onLoad`)
			this.props.choiceMediaPromiseResolve()
		}
	}

	render(){	
		const {image, expandImageAvailable,UIInverseScale} = this.props

		return(			
				<React.Fragment>					
					<img
						onLoad={this.onLoad}
						src={image.deliveryUrl}
						className={'slide-choice-media--image-image ' + (image.imageFill ? ' slide-choice-media--image-image--fill' : 'slide-choice-media--image-image--fit')}
					/>										
				</React.Fragment>			
			)		
			}	
	}


export default StaticSlideChoiceImage
