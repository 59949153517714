import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import isEqual from 'lodash/isEqual'
import HomePageContainer from './HomePageContainer'
import NowPlayingModalContainer from './NowPlayingModalContainer'
import { createSession, addAuthorizationToSession, fetchSession } from '../actions/session'
import { fetchSection } from '../actions/sections'
import { sectionPusherSetup } from '../utils/sectionPusher'
import syncClock from '../actions/syncClock'

const COUNTDOWN_SECONDS = 3

// check if multiple sections have active NP
// only show NP countdown if is only one section with NP
function findUniqueNPSection(sections) {
  let uniqueNPSection = null
  let sectionsWithNPCount = 0
  sections.forEach((section) => {
    if (section.nowPlaying) {
      sectionsWithNPCount += 1
      if (sectionsWithNPCount === 1) {
        uniqueNPSection = section
      }
    }
  })
  if (sectionsWithNPCount === 1) {
    return uniqueNPSection
  } return null
}

class AppContainer extends Component {
  constructor() {
    super()
    this.openNowPlaying = this.openNowPlaying.bind(this)
    this.closeNowPlaying = this.closeNowPlaying.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.clearTimer = this.clearTimer.bind(this)
    this.countDown = this.countDown.bind(this)
    this.beginNPCountdown = this.beginNPCountdown.bind(this)
    this.addStudentAuthorization = this.addStudentAuthorization.bind(this)
    this.checkForUniqueNPAfterAddAuth = this.checkForUniqueNPAfterAddAuth.bind(this)

    if (window.location.hash === '#now-playing') { // navigate to home page on app load
      window.location.hash = ''
    }

    // used for NP countdown shown on home page when a teacher starts playing something.
    this.timer = 0

    this.state = ({
      sectionInNowPlaying: null,
      countdownSectionId: null,
      activeCountdownSeconds: COUNTDOWN_SECONDS,
      pendingAuthorization: null,
      editAuthorizationsMode: false,
    })
  }

  componentDidMount() {
    if (!this.props.session) {
      this.props.createSession()
    } else {
      this.props.fetchSession(this.props.session.id).then((response) => {
        if (response) {
          response.authorizations.forEach((authorization) => {
            this.props.fetchSection(authorization.section.id)
            sectionPusherSetup(authorization.section.id, authorization.student.id)
          })
        }
      })
    }
    setTimeout(() => { // wait for initial api requests to finish
      this.props.syncClock()
    }, 5000)

    // Load YouTube stuff
    const tag = document.createElement('script')
    tag.src = `https://www.youtube.com/iframe_api?${Date.now()}`
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    if (process.env.REACT_APP_ENV !== 'production') { // for dev
      console.log('********************')
      console.log(process.env.REACT_APP_ENV)
      console.log('********************')
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.state.pendingAuthorization === null) {
      if (!isEqual(this.props.sections, nextProps.sections)) { // Sections has changed
        if (
          nextProps.session &&
          nextProps.session.authorizations.length === nextProps.sections.length
        ) { // has loaded all the sections
          const uniqueNPSection = findUniqueNPSection(nextProps.sections)
          if (uniqueNPSection) {
            const oldSection = find(this.props.sections, { id: uniqueNPSection.id })
            if (!oldSection || !oldSection.nowPlaying) {
              this.beginNPCountdown(uniqueNPSection.id)
            }
          } else { // if not a unique NP make sure to clear timer
            this.clearTimer()
          }
        }
      }
    }
    if (this.props.session && !nextProps.session) {
      this.clearTimer()
    }
  }

  checkForUniqueNPAfterAddAuth() {
    const uniqueNPSection = findUniqueNPSection(this.props.sections)
    if (uniqueNPSection) {
      this.beginNPCountdown(uniqueNPSection.id)
    } else { // if not a unique NP make sure to clear timer
      this.clearTimer()
    }
  }

  addStudentAuthorization(studentCode) {
    this.clearTimer()
    this.setState({
      pendingAuthorization: {
        id: null,
        sectionPreAuthorized: false,
        authorizationRequestSent: true,
        authorizationRequestStatus: null,
      },
    })
    const previousAuthorizations = this.props.session.authorizations
    return this.props.addAuthorizationToSession(this.props.session.id, studentCode)
      .then((response) => {
        let newAuthorization
        response.authorizations.forEach((authorization) => {
          const authorizationIndex = findIndex(
            previousAuthorizations,
            (o) => o.student.id === authorization.student.id,
          )
          if (authorizationIndex === -1) {
            newAuthorization = authorization
          }
        })
        this.setState({
          pendingAuthorization: {
            section: newAuthorization.section,
            student: newAuthorization.student,
            id: newAuthorization.id,
            sectionPreAuthorized: false,
            authorizationRequestSent: true,
            authorizationRequestStatus: 'success',
          },
        })
        setTimeout(() => {
          this.setState({ pendingAuthorization: null })
          this.checkForUniqueNPAfterAddAuth()
        }, 3500) // wait for animation to finish
      })
      .catch(() => {
        this.setState({
          pendingAuthorization: {
            id: null,
            sectionPreAuthorized: false,
            authorizationRequestSent: true,
            authorizationRequestStatus: 'failure',
          },
        })
      })
  }

  beginNPCountdown(sectionId) {
    if (window.location.hash !== '#now-playing' && !this.state.editAuthorizationsMode) {
      this.setState({ countdownSectionId: sectionId })
      this.startTimer()
    }
  }

  startTimer() {
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000)
    }
  }

  clearTimer() {
    clearTimeout(this.timer)
    this.setState({ activeCountdownSeconds: COUNTDOWN_SECONDS, countdownSectionId: null })
    this.timer = 0
  }

  countDown() {
    // TODO: fix and remove eslint-disable
    /* eslint-disable react/no-access-state-in-setstate */
    const activeCountdownSeconds = this.state.activeCountdownSeconds - 1
    this.setState({
      activeCountdownSeconds,
    })
    if (activeCountdownSeconds === 0) {
      clearInterval(this.timer)
      this.timer = 0
      this.openNowPlaying(this.state.countdownSectionId)
      this.setState({ activeCountdownSeconds: COUNTDOWN_SECONDS, countdownSectionId: null })
    }
    /* eslint-enable react/no-access-state-in-setstate */
  }

  openNowPlaying(sectionId) {
    if (!this.state.editAuthorizationsMode) {
      this.clearTimer()
      window.location.hash = 'now-playing'
      this.setState({ sectionInNowPlaying: sectionId })
    }
  }

  closeNowPlaying() {
    window.location.hash = ''
    setTimeout(() => {
      this.setState({ sectionInNowPlaying: null })
    }, 500)
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/:studentCode?">
            <HomePageContainer
              openNowPlaying={this.openNowPlaying}
              pendingAuthorization={this.state.pendingAuthorization}
              countdownSectionId={this.state.countdownSectionId}
              activeCountdownSeconds={this.state.activeCountdownSeconds}
              addStudentAuthorization={this.addStudentAuthorization}
              clearNPTimer={this.clearTimer}
              editAuthorizationsMode={this.state.editAuthorizationsMode}
              toggleEditAuthorizationsMode={() => {
                this.setState(
                  (prevState) => ({ editAuthorizationsMode: !prevState.editAuthorizationsMode }),
                )
              }}
            />
          </Route>
        </Switch>
        <NowPlayingModalContainer
          closeNowPlaying={this.closeNowPlaying}
          sectionId={this.state.sectionInNowPlaying}
        />
      </Router>
    )
  }
}

export default connect(
  (state) => ({
    session: state.session,
    sections: state.sections,
  }),
  {
    createSession,
    addAuthorizationToSession,
    fetchSection,
    fetchSession,
    syncClock,
  },
)(AppContainer)
