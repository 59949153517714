// "nowPlaying": {
//     "scanning": false,
//     "setPoll": "5ea8bbce2b2d490016569e35",
//     "currentPoll": null,
//     "setPollPoll": "aad5c96e",
//     "showGraph": true,
//     "revealAnswer": true,
//     "cleared": null,
//     "modified": "2020-05-08T23:33:16.302Z",
//     "results": {
//       "A": 2,
//       "B": 18,
//       "C": 0,
//       "D": 1
//     },

export default function calculateCorrectPercent(nowPlaying) {
  let percentCorrect = 0
  let totalResponses = 0
  let totalCorrect = 0
  const { choices } = nowPlaying.snapshot
  const { results } = nowPlaying

  if (choices[0].correct) {
    totalCorrect += results.A
  }
  totalResponses += results.A
  if (choices[1].correct) {
    totalCorrect += results.B
  }
  totalResponses += results.B
  if (choices[2]) {
    totalResponses += results.C
    if (choices[2].correct) {
      totalCorrect += results.C
    }
  }
  if (choices[3]) {
    totalResponses += results.D
    if (choices[3].correct) {
      totalCorrect += results.D
    }
  }
  if (totalResponses !== 0) {
    percentCorrect = (totalCorrect / totalResponses) * 100
  }
  return percentCorrect
}
