import React, { Component } from 'react'
import { Transition } from 'react-spring/renderprops'

class NowPlayingTransitionComponent extends Component {
  // constructor() {
  //   super()
  //   this.state = ({
  //     isFirstLoad: true,
  //   })
  // }

  // componentDidMount() {
  //   this.setState({ isFirstLoad: false })
  // }

  render() {
    const { nowPlaying } = this.props
    // const { isFirstLoad } = this.state
    let activePollId
    if (nowPlaying) {
      const setPollPollId = nowPlaying.setPollPoll
      const questionPollId = nowPlaying.currentPoll
      activePollId = setPollPollId || questionPollId
    }

    return (

      <Transition
        items={activePollId}
        // we couldn't get animation to work quite right,
        // can probably remove the whole animation stuff
        // from={{
        //   opacity: `${isFirstLoad?100:0}%`,
        //   transform: `translateY(${isFirstLoad?0:25}px)`,
        // }}
        enter={{ opacity: '100%', transform: 'translateY(0px)' }}
        leave={{ opacity: '0%', transform: 'translateY(0px)' }}
        config={{ duration: 0 }}
      >
        {(activePollId) => activePollId && ((style) => (
          <div style={{ ...style }} className="nowPlaying-slideContainerOuter">
            {this.props.children}
          </div>
        )
        )}
      </Transition>

    )
  }
}

export default NowPlayingTransitionComponent
