export default function (state = null, action) {
  switch (action.type) {
    case 'CREATE_PUSHER_SUCCESS':
      return action.pusher
    case 'CLEAR_PUSHER':
      return null
    default:
      return state
  }
}
