import React from 'react'
import slideLayoutDefinitions from '../../slideLayoutDefinitions.js'

class StaticSlideFillImage extends React.Component{


  render(){
    const {image, slideTemplate, questionMediaDynamicHeight} = this.props

    const imageContainerWidth = slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].width
    let imageContainerHeight = slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].height

    if(slideTemplate === 'bodyCenterMediaCenter'){
      imageContainerHeight = questionMediaDynamicHeight
    }
      
    return(             
      <div id="fillImageZoomable" style={{width: `${imageContainerWidth}px`, height: `${imageContainerHeight}px`}}  className='slide-media--imageCover slide-media--imageCover--fill'>
        {/*          
          Image Container Width: {imageContainerWidth}<br/>
          Image Container Height: {imageContainerHeight}<br/>          
        */}
      </div>
    )   
  } 
}


export default StaticSlideFillImage
