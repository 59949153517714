import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import NowPlayingContainer from './NowPlayingContainer'

class NowPlayingModalContainer extends Component {
  render() {
    let visible = false
    if (window.location.hash === '#now-playing') {
      visible = true
    }
    return [
      <React.Fragment key="liveView">
        <NowPlayingContainer
          key="liveViewContainer"
          visible={visible}
          sectionId={this.props.sectionId}
          closeNowPlaying={this.props.closeNowPlaying}
        />
      </React.Fragment>,
      <React.Fragment key="documentTitle">
        {visible &&
        <DocumentTitle title="Now Playing – Plickers" />}
      </React.Fragment>,
    ]
  }
}

function mapStateToProps(state) {
  const { control } = state
  const currentSection = null
  return {
    control,
    currentSection,
  }
}

export default withRouter(connect(
  mapStateToProps,
  { },
)(NowPlayingModalContainer))
