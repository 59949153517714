import React from 'react'
import YTReady from '../../../../youtubeReady'
import QuestionVideoControlBar from './QuestionVideoControlBar'
import {generateRandomId} from './generateRandomId'



class StaticSlideVideoPlayer extends React.Component{  

    constructor() {
      super()
      this.loadVideo=this.loadVideo.bind(this)
      this.playVideo=this.playVideo.bind(this)
      this.stopVideo=this.stopVideo.bind(this)
      this.pauseVideo=this.pauseVideo.bind(this)    
      this.onPlayerReady=this.onPlayerReady.bind(this)
      this.onStateChange=this.onStateChange.bind(this)
      this.toggleMuteVideo=this.toggleMuteVideo.bind(this)
      this.updatePlayerCurrentTime=this.updatePlayerCurrentTime.bind(this)
      this.startTimer=this.startTimer.bind(this)
      this.stopTimer=this.stopTimer.bind(this)
      this.seekTo=this.seekTo.bind(this)
      this.onClipFinished=this.onClipFinished.bind(this)
      this.onVideoEnd=this.onVideoEnd.bind(this)
      this.restartVideo=this.restartVideo.bind(this)

      this.playerId=generateRandomId()

       this.state = {      
        playerReady:false,
        isPlaying:false,
        isMuted:false,
        playerCurrentTime:0,
        isDraggingSeekBar:false,
        isBuffering:false
      }

      this.timer=null

}

 componentDidMount(){  
  let youtubeReady = YTReady
  youtubeReady.then( (YT) => {
   this.loadVideo(YT)
  })
 }

  componentWillUnmount() {
    if(this.state.playerReady){
      this.stopTimer()
      this.stopVideo()
    }
  }

  loadVideo(YT){
    const {video} = this.props
    this.player = new YT.Player(`youtube-player-${this.playerId}`, {
      videoId: video.youtubeId,      
      playerVars: {                                              
        'controls':0, // disable controls
        //'cc_load_policy': 1, // don't know what this does
        'loop': 0, //loop video
        'autoplay': 0, // autoplay                          
        'fs': 0, // show full screen option
        'disablekb': 1, // disable keyboard shortcuts      
        'rel': 0, //either hides related (depreciated) or only shows from same chanel
        'iv_load_policy': 3, // don't show video annotations by default
        'hl':'en', //interface language
        'cc_lang_pref':'en',
        //don't know what these do, from EdPuzzle
        'playsInline':1, 
        'showinfo':0, // undocumented, should hide title but I guess depreciated
        'wmode':'opaque', // undocumented
        'start':(this.props.start),
        'end':(this.props.end+1)
      },
      host: 'http://www.youtube-nocookie.com',
      height: '100%',
      width: '100%',
      events: {
        onReady: this.onPlayerReady,
        onStateChange:this.onStateChange,
        onError:this.onError
      },
    });
  };

 onError(error){
  }

  onPlayerReady(event){
    const muted=this.player.isMuted()
    this.setState({playerReady:true,isMuted:muted})
  }

  onStateChange(event){
    switch(event.data) {
      case -1://unstarted
        this.stopTimer()
        this.setState({isBuffering:true})        
        break;
      case 0://ended
        this.setState({isBuffering:false,playerCurrentTime:this.props.end})
        this.stopTimer()
        this.onVideoEnd()
        break;
      case 1://playing
        this.startTimer()
        this.setState({isBuffering:false,isPlaying:true})
        this.playVideo()        
        break;
      case 2://paused
        this.stopTimer()
        this.setState({isPlaying:false,isBuffering:false})
        break;
      case 3://BUFFERING
        this.stopTimer()
        this.setState({isBuffering:true})
      default:
        break;
    }
  }

  
  stopVideo(){
    this.player.stopVideo()
  }

  playVideo(){
    const playerState=this.player.getPlayerState()
    if(playerState===0){
      this.seekTo(this.props.start)
      this.setState({playerCurrentTime:this.props.start})
    }
    this.setState({isPlaying:true,isPaused:false})
    this.player.playVideo()
  }

  pauseVideo(){
    this.setState({isPlaying:false})
    this.player.pauseVideo()
  }

  startTimer(){
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 50)
  }

  stopTimer(){
    if(this.timer){
      clearInterval(this.timer)
    }
  }


  updatePlayerCurrentTime(){
    if(!this.state.isBuffering){
      const currentTime=this.player.getCurrentTime()
      if(currentTime >this.state.playerCurrentTime){ 
        if(currentTime<this.props.end){
          this.setState({playerCurrentTime:currentTime})
        }else{
            this.onClipFinished()
        }
      }
    }
  }

  onClipFinished(){
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({isPlaying:false,playerCurrentTime:this.props.start})
  }

  onVideoEnd(){
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({playerCurrentTime:this.props.start})
  }

  seekTo(seconds){
    this.setState({isBuffering:true,playerCurrentTime:seconds})
    this.player.seekTo(seconds, true)
  }

  restartVideo(){
    this.setState({isBuffering:true,playerCurrentTime:this.props.start})
    this.player.seekTo(this.props.start)   
  }


  toggleMuteVideo(){
    const player=this.player
    if(player.isMuted()){
      player.unMute()
    }else{
      player.mute()
    }
    this.setState({isMuted:!player.isMuted()})
  }






  render(){ 

  	const {slideWidth, slideTemplate, video} = this.props
    const {isDraggingSeekBar} = this.state

    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale

    const ceilingInverseScale = 1.55 

    const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)


    let mediaContainerWidth // container size
    let mediaContainerHeight // container size
    
    if((slideTemplate === 'bodyLeftMediaRight') || (slideTemplate === 'bodyRightMediaLeft')){
      mediaContainerWidth = 580 // should come from template definitions or somewhere
      mediaContainerHeight = 680 // should come from template definitions or somewhere
    }
    if(slideTemplate === 'bodyLeftMediaRightChoicesMedia'){
      mediaContainerWidth = 580 // should come from template definitions or somewhere
      mediaContainerHeight = 311 // should come from template definitions or somewhere
    }
    if(slideTemplate === 'bodyCenterMediaCenter'){
      mediaContainerWidth = 1160 // should come from template definitions or somewhere
      mediaContainerHeight = this.props.questionMediaDynamicHeight // pass in
    }


    const intrinsicVideoWidth = this.props.videoWidth
    const intrinsicVideoHeight = this.props.videoHeight

    const intrinsicVideoAspect = intrinsicVideoWidth / intrinsicVideoHeight
    const mediaContainerAspect = mediaContainerWidth / mediaContainerHeight

    let videoContainerWidth
    let videoContainerHeight

    // if video is more-landscape than container
    if(intrinsicVideoAspect >= mediaContainerAspect){
      videoContainerWidth = mediaContainerWidth
      videoContainerHeight = mediaContainerWidth / intrinsicVideoAspect
    }

    // if video is more-portrait than container
    if(intrinsicVideoAspect < mediaContainerAspect){
      videoContainerHeight = mediaContainerHeight
      videoContainerWidth = mediaContainerHeight * intrinsicVideoAspect
    }

    // need to resize controlbar width 
    const controlBarWidth = videoContainerWidth / inverseScale

    const scaledVideoWidth = videoContainerWidth / inverseScale
    const scaledVideoHeight = videoContainerWidth * (intrinsicVideoHeight / intrinsicVideoWidth) / inverseScale

    // // delete button needs to be aligned to video
    // let deleteVideoYPosition
    // let deleteVideoXPosition

    // if((slideTemplate === 'bodyLeftMediaRight') || (slideTemplate === 'bodyRightMediaLeft')){
    //   deleteVideoYPosition = 0
    //   deleteVideoXPosition = -12
    // }
    // if(slideTemplate === 'bodyCenterMediaCenter'){
    //   deleteVideoYPosition = (mediaContainerWidth - videoContainerWidth) / 2 - 6
    //   deleteVideoXPosition = 8
    // }

    // // adjust to offset to right of edge
    // const deleteVideoAdjustedYPosition = deleteVideoYPosition - 12
  

	  return (

	  	  <React.Fragment>  			
          <div style={{width: `${videoContainerWidth}px`, height:`${videoContainerHeight}px`}} className={'slide-media--video-videoContainer ' + (isDraggingSeekBar ? 'slide-media--video-videoContainer--isSeeking' : '')}>
            <div className='slide-media--video-videoScaleContainer' style={{transform: `scale(${inverseScale})`, width: `${scaledVideoWidth}px`, height: `${scaledVideoHeight}px`}} >
              <div id={`youtube-player-${this.playerId}`} className="slide-media--video-video" />
            </div>
            <div style={{transform: `scale(${inverseScale})`, width: `${controlBarWidth}px`}} className='slide-media--video-controlBarContainer'>
              {this.state.playerReady &&
	              <QuestionVideoControlBar 
	                playVideo={this.playVideo}
	                pauseVideo={this.pauseVideo}
	                YTPlayer={this.player}
	                clipStartTime={video.start}
	                clipEndTime={video.end}
	                duration={video.end - video.start}
	                isPlaying={this.state.isPlaying}
	                isMuted={this.state.isMuted}
	                toggleMuteVideo={this.toggleMuteVideo}
	                playerCurrentTime={this.state.playerCurrentTime}
	                seekTo={this.seekTo}
                  restartVideo={this.restartVideo}
                  onBeforeChange={()=>{this.setState({isDraggingSeekBar:true})}}
                  onAfterChange={()=>{this.setState({isDraggingSeekBar:false})}}
	              />
            	}
            </div>
          </div>      
	     	  </React.Fragment>  
	  )
	}
}


export default StaticSlideVideoPlayer



